<template>
  <FormComponent
    :title="`Invoice ${invoiceDetails.invoice_no} - Credit Note`"
    :backRoute="backRoute"
  >
    <template #formContent>
      <v-row class="ma-2">
        <v-col cols="1" class="Form-Columns-Dense">
          <InputField
            hide-details="auto"
            type="number"
            :label="`Total Amount (${$globals.currency})`"
            readonly
            :clearable="false"
            v-model="invoiceDetails.total_amount"
          />
        </v-col>
        <v-col cols="1" class="Form-Columns-Dense">
          <InputField
            hide-details="auto"
            type="number"
            :label="`Total Tax Amount (${$globals.currency})`"
            readonly
            :clearable="false"
            v-model="invoiceDetails.total_tax_amount"
          />
        </v-col>
        <v-col cols="1" class="Form-Columns-Dense">
          <InputField
            hide-details="auto"
            type="number"
            :label="`Total Gross Amount (${$globals.currency})`"
            readonly
            :clearable="false"
            v-model="invoiceDetails.total_gross_amount"
          />
        </v-col>
        <v-col cols="2" class="Form-Columns-Dense">
          <v-text-field
            label="Search On Enter"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            @click:clear="onEnter"
            hide-details="auto"
            v-model="filters.search"
            v-on:keyup.enter="onEnter"
          ></v-text-field>
        </v-col>
        <v-col>
          <b>Total Invoice DA</b>
          <v-badge
            v-if="invoiceDetails.total_invoice_das"
            top
            :content="invoiceDetails.total_invoice_das"
            overlap
            class="mb-4 ml-3"
          >
          </v-badge>
        </v-col>

        <v-col cols="1" class="Form-Columns-Dense">
          <v-checkbox
            class="ma-0"
            hide-details="auto"
            v-model="fullIvoicecredit"
            label="Full Amount Credit"
            @change="fulInvoiceCreditNote()"
          ></v-checkbox>
        </v-col>
        <v-col cols="2" class="Form-Columns-Dense">
          <InputField
            label="Credit Note Remarks"
            hide-details="auto"
            v-model="remarks"
          />
        </v-col>

        <v-col cols="1" class="Form-Columns-Dense">
          <InputField
            hide-details="auto"
            type="number"
            :label="`Total Credit Amount (${$globals.currency})`"
            readonly
            :clearable="false"
            v-model="total_credit_amount"
          />
        </v-col>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="primary"
              @click="previewCreditNote"
              v-bind="attrs"
              :disabled="grid2Data.length <= 0"
              v-on="on"
            >
              mdi-eye
            </v-icon>
          </template>
          <span>Preview Credit Note</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="primary"
              @click="clearGrid2Data"
              v-bind="attrs"
              :disabled="grid2Data.length <= 0"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Remove Data from list</span>
        </v-tooltip>

        <v-btn
          depressed
          color="primary"
          class="ml-2 mt-2"
          small
          :disabled="grid2Data.length <= 0"
          @click="createCreditNote()"
        >
          Create Credit Note
        </v-btn>

        <v-col cols="12" class="py-2" v-if="invoiceAdditionalChargeList.length">
          <v-row align="center">
            <v-col cols="5">
              <AgGridVue
                ref="firstGrid"
                style="width: 100%; height: calc(110vh - 357px)"
                class="small-table ag-theme-alpine"
                id="legsGrid1"
                :columnDefs="headers"
                :context="context"
                @grid-ready="onGridReady1"
                :defaultColDef="defaultColDef"
                :grid-options="gridOptions"
                :suppressDragLeaveHidesColumns="true"
                :rowData="invoiceAdditionalChargeList"
                :rowSelection="'multiple'"
              ></AgGridVue>
              <Pagination
                :pageNo="pageNo1"
                :totalItems="totalItems1"
                :pageSize="itemsPerPage1"
                :itemsPerPageValues="setItemsPerPage1"
                @itemsPerPageChange="itemsPerPageChanged1"
                @prevPage="prevPage1"
                @nextPage="nextPage1"
              />
            </v-col>
            <v-col cols="1" class="d-flex align-center justify-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="primary"
                    @click="moveSelectedRows"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon large>mdi-arrow-right</v-icon>
                  </v-btn>
                </template>
                <span>Move Selected Rows</span>
              </v-tooltip>
            </v-col>
            <v-col cols="6">
              <AgGridVue
                ref="secondGrid"
                style="width: 100%; height: calc(110vh - 357px)"
                class="small-table ag-theme-alpine"
                id="legsGrid2"
                :columnDefs="creditNoteheaders"
                :context="context"
                @grid-ready="onGridReady2"
                :defaultColDef="defaultColDef"
                :grid-options="creditNoteGridOptions"
                :suppressDragLeaveHidesColumns="true"
                :rowData="grid2Data"
                :rowSelection="'multiple'"
              ></AgGridVue>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" class="py-2"> </v-col>
      </v-row>

      <CustomerInvoiceCreditChargeFormDialog
        v-model="showInvoiceAdditionalChargeForm"
        :isEditing="isEditing"
        @refreshList="refreshData"
        :isReadOnly="isReadOnly"
        :selectedChargeData="chargeData"
        @update:selectedChargeData="updateSelectedChargeData"
      />
      <CreditNotePreview
        v-model="showInvoicePreview"
        :invoiceObject="previewObject"
        @refreshList="refreshCreditNote()"
      />
    </template>
  </FormComponent>
</template>

<script>
import ListComponent from "@/components/General/ListComponent.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";
import createCreditNoteButton from "@/components/AgGridComponents/createCreditNoteButton.vue";
import CustomerInvoiceCreditChargeFormDialog from "@/components/ModuleBased/dialogs/CustomerInvoiceCreditChargeFormDialog.vue";
import CreditNotePreview from "@/components/ModuleBased/dialogs/CreditNotePreview.vue";

export default {
  components: {
    ListComponent,
    AgGridVue,
    InputField,
    Pagination,
    CreditNotePreview,
    CustomerInvoiceCreditChargeFormDialog,
    FormComponent,
    createCreditNoteButton,
  },
  data() {
    return {
      backRoute: { path: "/app/admin/invoice/customer/list" },
      invoiceDetails: {},
      creditNotePayload: {},
      invoiceAdditionalChargeList: [],
      setItemsPerPage1: [10, 15, 20, 50, 100, 500],

      showInvoiceAdditionalChargeForm: false,
      isEditing: false,
      selectedDaId: null,
      selectedDaIndex: null,
      selectedChargeIndex: null,
      selectedChargeData: {},
      showInvoicePreview: false,
      total_credit_amount: 0,
      fullIvoicecredit: false,
      remarks: null,

      chargeData: {},
      headers: [
        {
          headerName: "DA No",
          field: "da_no",
          checkboxSelection: true,
          minWidth: 130,
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },

        {
          headerName: "Id",
          field: "id",
          minWidth: 50,
          hide: true, // Hide the column
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: "Charge",
          field: "charge_title",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: "Revenue Gl No",
          field: "gl_no",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "rate",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: "TAX(%)",
          field: "tax_percentage",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: `TAX Amount (${this.$globals.currency})`,
          field: "tax_amount",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: `Amount With Tax (${this.$globals.currency})`,
          field: "amount_with_tax",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
      ],
      creditNoteheaders: [
        {
          headerName: "DA No",
          field: "da_no",
          minWidth: 120,
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: "Full Amount",
          field: "full_amount_credit",
          valueGetter: function (params) {
            return params.data.full_amount_credit != null
              ? params.data.full_amount_credit
              : "No";
          },
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: "Id",
          field: "id",
          minWidth: 50,
          hide: true, // Hide the column
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: "Charge",
          field: "charge_title",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: "Revenue Gl No",
          field: "gl_no",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "rate",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: "TAX(%)",
          field: "tax_percentage",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: `TAX Amount (${this.$globals.currency})`,
          field: "tax_amount",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: `Amount With Tax (${this.$globals.currency})`,
          field: "amount_with_tax",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: `Credit Limit (${this.$globals.currency})`,
          field: "charge_credit_amount",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },

        {
          headerName: "Actions",
          field: "actions",
          pinned: "right",
          minWidth: 100,

          cellRendererFramework: "createCreditNoteButton",
        },
      ],
      chargeId: null,
      invoiceChargesTimeout: null,
      showInvoicePreview: false,
      // pagination vars for Grid 1
      pageNo1: 1,
      itemsPerPage1: 100,
      totalItems1: 0,
      // data for Grid 2
      grid2Data: [],
      containerCentric: "No",
      filters: {},
      previewObject: {
        delivery_advices: [],
      },

      // ag-grid vars
      gridApi1: null,
      gridColumnApi1: null,
      gridApi2: null,
      gridColumnApi2: null,
      defaultColDef: {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        rowHeight: 35,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
      },
      creditNoteGridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        rowHeight: 35,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
      },
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    isReadOnly() {
      return this.invoiceDetails && this.invoiceDetails.status == "Accepted";
    },
    offset1() {
      return this.itemsPerPage1 * (this.pageNo1 - 1);
    },
  },
  methods: {
    clearGrid2Data() {
      this.grid2Data = [];
    },
    refreshCreditNote() {
      this.previewObject = {
        delivery_advices: [],
      };
    },
    previewCreditNote() {
      this.grid2Data.forEach((item, index) => {
        this.previewObject.invoice_from_date = item.invoice_from_date;
        this.previewObject.invoice_to_date = item.invoice_to_date;
        this.previewObject.invoice_date = item.invoice_date;
        this.previewObject.invoice_type = item.invoice_type;
        this.previewObject.branch = item.branch;
        this.previewObject.is_international_freight =
          item.is_international_freight;
        this.previewObject.remarks = item.remarks;
        this.previewObject.customer = item.customer;
        // this.previewObject.delivery_advices.push(item.delivery_advice);
        // this.previewObject.charge_credit_amount = parseFloat(item.charge_credit_amount);
        this.previewObject.delivery_advices.push({
          delivery_advice: item.delivery_advice,
          charge_type: item.charge_type, // Add charge_type field
          charge_credit_amount: isNaN(parseFloat(item.charge_credit_amount))
            ? "0.00"
            : parseFloat(item.charge_credit_amount).toFixed(2),
        });
        console.log("1222", item);
      });
      this.showInvoicePreview = true;
    },
    fulInvoiceCreditNote() {
      if (this.fullIvoicecredit) {
        this.invoiceAdditionalChargeList.forEach((item) => {
          // Check if the item already exists in the target array
          const exists = this.grid2Data.some(
            (targetItem) => targetItem.id === item.id
          );

          if (!exists) {
            // Modify the item properties
            item.full_amount_credit = "Yes";
            item.charge_credit_amount = -item.amount_with_tax;
            item.fullInvoiceCreidit = true;

            // Push the modified item to grid2Data
            this.grid2Data.push(item);
          }
        });
        this.checkToalCreditAmount();
      } else {
        this.grid2Data = [];
        this.total_credit_amount = null;
      }
    },

    createCreditNote() {
      this.$bus.$emit("showLoader", true);
      const payload = {};
      let inValid = this.validateGrid2Data();
      if (inValid) {
        this.$bus.$emit("showLoader", false);
        return;
      }

      payload.remarks = this.remarks;
      payload.customer_invoice = this.invoiceDetails.id;

      this.$bus.$emit("showLoader", false);
      if (this.fullIvoicecredit) {
        this.$api.customerInvoice
          .createFullCreditNote(payload)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Credit Note Created successfully!",
              color: "success",
            });
            this.$router.push(`/app/admin/invoice/customer/list`);
          })
          .catch((err) => {
            this.formErrors = err.data;
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't Add Credit Note!",
              color: "error",
            });
          });
      } else {
        payload.total_credit_amount = this.total_credit_amount;
        payload.credit_note_invoice_charges = this.grid2Data;
        this.$api.customerInvoice
          .createCustomerInvoiceCreditNote(payload)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Credit Note Created successfully!",
              color: "success",
            });
            this.$router.push(`/app/admin/invoice/customer/list`);
          })
          .catch((err) => {
            this.formErrors = err.data;
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't Add Credit Note!",
              color: "error",
            });
          });
      }
    },
    validateGrid2Data() {
      for (const item of this.grid2Data) {
        if (
          item.full_amount_credit === "Yes" &&
          item.charge_type === "Freight"
        ) {
          const daNo = item.da_no;
          const daOtherExistsInInvoice = this.invoiceAdditionalChargeList.some(
            (additionalItem) =>
              additionalItem.da_no === daNo &&
              additionalItem.charge_type === "Other"
          );
          const daOtherExistsInGrid2 = this.grid2Data.some(
            (gridItem) =>
              gridItem.da_no === daNo && gridItem.charge_type === "Other"
          );

          if (daOtherExistsInInvoice && !daOtherExistsInGrid2) {
            this.$bus.$emit("showToastMessage", {
              message: `Error: 'Add Additional charge with DA No ${daNo}.`,
              color: "error",
              timeOut: 5000,
            });
            return true;
          }
          return false;
        }
      }
    },
    checkToalCreditAmount() {
      let daNos = new Set();

      // Step 1: Find and store da_no for entries with charge_type 'transport' and full_amount 'yes'
      this.grid2Data.forEach((item) => {
        if (
          item.charge_type === "Freight" &&
          item.full_amount_credit === "Yes"
        ) {
          daNos.add(item.da_no);
        }
      });

      // Step 2: Update other entries with the same da_no but different charge_type
      this.grid2Data.forEach((item) => {
        if (daNos.has(item.da_no) && item.charge_type == "Other") {
          item.full_amount_credit = "Yes";
          item.charge_credit_amount = -item.amount_with_tax;
        }
      });

      if (this.gridApi2) {
        this.gridApi2.setRowData(this.grid2Data);
      }

      // Step 3: Calculate the total of charge_credit_amount

      this.total_credit_amount = this.grid2Data.reduce((sum, item) => {
        const chargeCreditAmount =
          typeof item.charge_credit_amount === "number"
            ? item.charge_credit_amount
            : Number(item.charge_credit_amount);
        return sum + chargeCreditAmount;
      }, 0);
    },
    updateSelectedChargeData(updatedItem) {
      let da_no = updatedItem.da_no;
      if (updatedItem.charge_type == "Freight") {
        // Filter items from invoiceAdditionalChargeList based on da_no and charge_type
        let additional_charge = this.invoiceAdditionalChargeList.filter(
          (da) => {
            return da.charge_type == "Other" && da.da_no == da_no;
          }
        );

        // Loop through the filtered items and add them to grid2Data if they don't exist already
        additional_charge.forEach((selectedItem) => {
          const exists = this.grid2Data.some(
            (existingItem) => existingItem.id === selectedItem.id
          );
          if (!exists) {
            this.grid2Data.push(selectedItem);
          }
        });
      }

      const indexToUpdate = this.grid2Data.findIndex(
        (item) => item.id === updatedItem.id
      );
      // Check if the object exists in the array
      if (indexToUpdate !== -1) {
        // Update the object at the found index
        this.grid2Data[indexToUpdate] = updatedItem;

        // Update the grid2Data array
        this.grid2Data = [...this.grid2Data];
        this.checkToalCreditAmount();
      }
    },

    onEnter() {
      this.pageNo1 = 1;
      this.getDeliveryAdviceInvoiceCharges(this.$route.params.id);
    },

    moveSelectedRows() {
      const selectedNodes = this.gridApi1.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => ({ ...node.data }));

      // Get all rows in the grid
      const allRowNodes = [];
      this.gridApi1.forEachNode((rowNode) => allRowNodes.push(rowNode));

      selectedData.forEach((selectedItem) => {
        const exists = this.grid2Data.some(
          (existingItem) => existingItem.id === selectedItem.id
        );
        if (!exists) {
          this.grid2Data.push(selectedItem);
        }
      });

      // Update the total items count
      this.totalItems2 = this.grid2Data.length;

      // Sort the grid2Data by da_no and id
      this.grid2Data.sort((a, b) => {
        if (a.da_no === b.da_no) {
          return a.id - b.id;
        }
        return a.da_no.localeCompare(b.da_no);
      });
    },

    updateAdditionalCharges(data) {
      this.chargeData = data;
      // this.chargeId = id;
      this.isEditing = true;
      this.showInvoiceAdditionalChargeForm = true;
    },

    removeDA(data) {
      if (
        confirm(
          "Are you sure you want to remove this DA/Items from the Credit Note?"
        )
      ) {
        const idToRemove = data.id; // Assuming 'id' is the identifier you are using to find the item to remove
        this.grid2Data = this.grid2Data.filter(
          (item) => item.id !== idToRemove
        );
        this.checkToalCreditAmount();
      }
    },

    refreshData() {
      this.getInvoiceObject(this.$route.params.id);
      this.getDeliveryAdviceInvoiceCharges(this.$route.params.id);

      this.isEditing = false;
      this.selectedDaId = null;
      this.selectedChargeData = {};
    },

    getInvoiceObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.customerInvoice
        .getCustomerInvoiceGroup(id)
        .then((res) => {
          this.invoiceDetails = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getDeliveryAdviceInvoiceCharges(id, params = {}) {
      if (this.invoiceChargesTimeout) {
        clearTimeout(this.invoiceChargesTimeout);
      }
      this.invoiceChargesTimeout = setTimeout(() => {
        params = {
          ...params,
          ...this.filters,
          offset: this.offset1,
          limit: this.itemsPerPage1,
          customer_invoice: id,
        };
        this.$bus.$emit("showLoader", true);
        this.$api.customerInvoice
          .getCustomerInvoiceAdditionalChargeList(params)
          .then((res) => {
            this.invoiceAdditionalChargeList = res.data;

            this.totalItems1 = res.count;
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      }, 100);
    },
    resizeGrid() {
      if (this.gridApi1) {
        setTimeout(() => {
          this.gridApi1.sizeColumnsToFit();
        }, 100);
      }
      if (this.gridApi2) {
        setTimeout(() => {
          this.gridApi2.sizeColumnsToFit();
        }, 100);
      }
    },
    onGridReady1(params) {
      this.gridApi1 = params.api;
      this.gridColumnApi1 = params.columnApi;
      this.resizeGrid();
    },
    onGridReady2(params) {
      this.gridApi2 = params.api;
      this.gridColumnApi2 = params.columnApi;
      this.resizeGrid();
    },
    itemsPerPageChanged1(e) {
      this.pageNo1 = 1;
      this.itemsPerPage1 = e;
      this.getDeliveryAdviceInvoiceCharges(this.$route.params.id);
    },
    prevPage1() {
      this.pageNo1--;
      this.getDeliveryAdviceInvoiceCharges(this.$route.params.id);
    },
    nextPage1() {
      this.pageNo1++;
      this.getDeliveryAdviceInvoiceCharges(this.$route.params.id);
    },
    setBackRoute() {
      if ("backRoute" in this.$route.query) {
        this.backRoute.path = this.$route.query.backRoute;
      }
      if ("active_tab" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.active_tab = this.$route.query.active_tab;
      }
    },
  },

  mounted() {
    if (
      "id" in this.$route.params &&
      typeof parseInt(this.$route.params.id) == typeof 1
    ) {
      this.getDeliveryAdviceInvoiceCharges(this.$route.params.id);
      this.getInvoiceObject(this.$route.params.id);
    }
    this.setBackRoute();
  },
};
</script>
