<template>
  <v-dialog width="500" v-model="showContainerTypeForm">
    <v-card>
      <v-card-title class="secondary white--text py-2 px-4">
        <span> {{ editMode ? "Update" : "Add" }} Container Type </span>

        <v-spacer />

        <v-btn icon @click="resetForm(), (showContainerTypeForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-2">
        <v-form v-model="isValid" ref="containerTypeForm">
          <v-row class="ma-0">
            <v-col cols="12" class="Form-Columns-Dense">
              <MultiSelect
                hide-details="auto"
                :items="allContainerCategoryList"
                itemText="name"
                itemValue="id"
                v-model="containerTypeDetails.container_category"
                :rules="[(val) => !!val || 'Container Category is Required!']"
                label="Select Container Category *"
                required
                :error-messages="formErrors.container_category"
                @input="delete formErrors.container_category"
              ></MultiSelect>
            </v-col>
            <v-col cols="12" class="Form-Columns-Dense">
              <InputField
                label="Container Type *"
                :rules="[(v) => !!v || 'Container Type is Required']"
                required
                hide-details="auto"
                v-model="containerTypeDetails.type_name"
                :error-messages="formErrors.type_name"
                @input="delete formErrors.type_name"
              />
            </v-col>

            <v-col cols="12" class="Form-Columns-Dense">
              <InputField
                type="number"
                label="Container Size (Feet) *"
                :rules="[(val) => !!val || 'Container Size is Required!']"
                required
                hide-details="auto"
                v-model="containerTypeDetails.container_size"
                :error-messages="formErrors.container_size"
                @input="delete formErrors.container_size"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>
        <v-btn class="ml-3" color="secondary" depressed @click="resetForm">
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";

export default {
  components: {
    InputField,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    editContainerTypeID: {
      type: [Number, null],
      default: null,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getContainerCategoryList();
        if (this.editMode) {
          this.getContainerTypeObject(this.editContainerTypeID);
        }
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showContainerTypeForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      formErrors: {},
      allContainerCategoryList: [],
      isValid: true,

      containerTypeDetails: {},
    };
  },
  methods: {
    getContainerCategoryList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
      };

      this.$api.containerCategory
        .getContainerCategoryList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allContainerCategoryList = res.data;
          this.totalItems = res.count;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      if (!this.editMode) {
        this.$api.containerType
          .addContainerType(this.containerTypeDetails)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Container Type added Successfully!",
              color: "success",
            });
            this.resetForm();
            this.showContainerTypeForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't add Container Type!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        this.$api.containerType
          .updateContainerType({
            id: this.containerTypeDetails.id,
            data: this.containerTypeDetails,
          })
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Container Type updated Successfully!",
              color: "success",
            });
            this.resetForm();
            this.showContainerTypeForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't update Container Type!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      }
    },

    resetForm() {
      if (this.$refs.containerTypeForm) {
        this.$refs.containerTypeForm.reset();
      }
      this.containerTypeDetails = {};
    },

    getContainerTypeObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.containerType
        .getContainerTypeObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.containerTypeDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>
