<template>
  <v-dialog width="500" v-model="showContainerCategoryForm">
    <v-card>
      <v-card-title class="secondary white--text py-2 px-4">
        <span> {{ editMode ? "Update" : "Add" }} Container Category </span>

        <v-spacer />

        <v-btn icon @click="resetForm(), (showContainerCategoryForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-2">
        <v-form v-model="isValid" ref="containerCategoryForm">
          <v-row class="ma-0">
            <v-col cols="12" class="Form-Columns-Dense">
              <InputField
                label="Container Category *"
                :rules="[(v) => !!v || 'Container Category is Required']"
                required
                hide-details="auto"
                v-model="containerCategoryDetails.name"
                :error-messages="formErrors.name"
                @input="delete formErrors.name"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>
        <v-btn class="ml-3" color="secondary" depressed @click="resetForm">
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";

export default {
  components: {
    InputField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    editContainerCategoryID: {
      type: [Number, null],
      default: null,
    },
  },
  watch: {
    value(val) {
      if (val) {
        if (this.editMode) {
          this.getContainerCategoryObject(this.editContainerCategoryID);
        }
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showContainerCategoryForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      formErrors: {},
      isValid: true,

      containerCategoryDetails: {},
    };
  },
  methods: {
    submitForm() {
      this.$bus.$emit("showLoader", true);
      if (!this.editMode) {
        this.$api.containerCategory
          .addContainerCategory(this.containerCategoryDetails)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Container Type added Successfully!",
              color: "success",
            });
            this.resetForm();
            this.showContainerCategoryForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't add Container Type!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        this.$api.containerCategory
          .updateContainerCategory({
            id: this.editContainerCategoryID,
            data: this.containerCategoryDetails,
          })
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Container Type updated Successfully!",
              color: "success",
            });
            this.resetForm();
            this.showContainerCategoryForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't update Container Type!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      }
    },

    resetForm() {
      if (this.$refs.containerCategoryForm) {
        this.$refs.containerCategoryForm.reset();
      }
      this.containerCategoryDetails = {};
    },

    getContainerCategoryObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.containerCategory
        .getContainerCategoryObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.containerCategoryDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>
