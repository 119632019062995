<template>
  <ListComponent title="Sub Hire Job - Completed" :totalCount="totalItems">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2 d-flex align-center">
          <v-text-field
            label="Search On Enter"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            @click:clear="onEnter()"
            v-on:keyup.enter="onEnter"
            v-model="filters.search"
          />
          <Columns :headers="headers" :gridColInstance="gridColumnApi" />
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="8"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            @columnMoved="resizeGrid"
            @columnVisible="resizeGrid"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="subHireList"
          ></AgGridVue>
        </v-col>
      </v-row>
      <DetailsParent v-model="subHireJobLogDetailsDrawer" :resizable="true">
        <template #details>
          <v-card elevation="0">
            <v-card-title class="heading">
              <span class="text-h6">Sub Hire Job Logs</span>
            </v-card-title>

            <v-card-text>
              <v-row class="Order-Log-Scrolling-Class mt-3">
                <v-col cols="12">
                  <span
                    class="py-12"
                    v-if="subHireJobLogs && subHireJobLogs.length == 0"
                  >
                    No logs to show!
                  </span>

                  <v-timeline dense clipped v-else>
                    <v-timeline-item
                      v-for="(log, i) in subHireJobLogs"
                      :key="i"
                      icon-color="primary"
                      small
                    >
                      <v-card class="elevation-2">
                        <v-card-text>
                          <table class="Full-Width">
                            <tbody>
                              <tr>
                                <th class="text-left">{{ log.updated_by }}</th>
                                <td class="text-caption text-right">
                                  {{ log.created | formatDate(true) }}
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">{{ log.message }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </DetailsParent>
    </template>

    <template #dialogs>
      <SubHireDetailView
        v-model="showSubhireDetailDialog"
        :subHireID="subhirejobid"
      />
    </template>
  </ListComponent>
</template>

<script>
import ListComponent from "@/components/General/ListComponent.vue";
import Columns from "@/components/General/Columns.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import SubHireButton from "@/components/AgGridComponents/SubHireButton.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import SubHireDetailView from "@/components/ModuleBased/details/SubHireDetailView.vue";
import DetailsParent from "@/components/General/DetailsParent.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    Columns,
    AgGridVue,
    Pagination,
    SubHireButton,
    agColumnHeader: CustomHeader,
    SubHireDetailView,
    DetailsParent
  },

  data() {
    return {
      sorting: {},

      headers: [
        {
          headerName: "Sub Hire ID",
          field: "sub_hire_id",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Creation Date",
          field: "created",
          tooltipField: "created",
          sortable: true,
          minWidth: 120,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "Transporter",
          field: "transporter_company_name",
          tooltipField: "transporter_company_name",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Route",
          field: "leg_lane_title",
          tooltipField: "leg_lane_title",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: "DA(s)",
          field: "all_da_charge_details.sub_hire_leg_count",
          sortable: true,
          minWidth: 60,
        },
        {
          headerName: "Customer",
          field: "customer",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: `Revenue (${this.$globals.currency})`,
          field:
            "all_da_charge_details.all_subhire_da_rate_plus_additional_amount",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: `Tax Amount(${this.$globals.currency})`,
          field: "all_da_charge_details.all_subhire_da_total_taxable_amount",
          sortable: true,
          minWidth: 80,
        },
        {
          headerName: `Cost (${this.$globals.currency})`,
          field: "total_cost",
          tooltipField: "total_cost",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: `Tax Amount(${this.$globals.currency})`,
          field: "total_taxable_amount",
          sortable: true,
          minWidth: 80,
        },
        {
          headerName: `Margin Amount (${this.$globals.currency})`,
          field: "all_da_charge_details.margin_amount",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: "Margin %",
          field: "all_da_charge_details.margin_percentage",
          sortable: true,
          minWidth: 100,
        },

        {
          headerName: "Invoice Number",
          field: "invoice_no",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: "Invoice Date",
          field: "invoice_date",
          sortable: true,
          minWidth: 100,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "Completed By",
          field: "completed_by",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "SubHireButton",
          pinned: "right",
          minWidth: 150,
        },
      ],

      subHireList: [],
      subhirejobid: null,
      showSubhireDetailDialog: false,

      // Details vars
      selectedSubHireId: null,

      permissions: null,

      // pagination vars
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,

      // Filter vars
      filters: {},
      showFilterDialog: false,

      subHireJobLogDetailsDrawer: false,
      subHireJobLogs: [],

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },

  methods: {
    viewSubHireLogs(id) {
      this.subHireJobLogDetailsDrawer = true;
      this.$bus.$emit("showLoader", false);
      this.$api.subHire
        .getSubHireJObLogs(id)
        .then((res) => {
          this.subHireJobLogs = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't fetch sub hire job logs!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
    viewSubHire(id) {
      this.subhirejobid = id;
      this.showSubhireDetailDialog = true;
    },
    resizeGrid() {
      if (this.gridOptions && this.gridOptions.api) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(20);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    onEnter() {
      this.pageNo = 1;
      setTimeout(() => {
        this.getSubHireList();
      }, 10);
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getSubHireList();
    },
    prevPage() {
      this.pageNo--;
      this.getSubHireList();
    },
    nextPage() {
      this.pageNo++;
      this.getSubHireList();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    apporvedSubHire(id, status) {
      if (confirm(`Are you sure you want to approved the subhire`)) {
        this.$bus.$emit("showLoader", true);
        this.$api.subHire
          .approvedSubhire(id, status)
          .then((res) => {
            this.$emit("refreshList");
            this.$bus.$emit("showToastMessage", {
              message: "Sub Hire Approved Successfully!",
              color: "success",
            });
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            this.$bus.$emit("showToastMessage", {
              message: `${
                err.data && err.data.non_field_errors
                  ? err.data.non_field_errors.join()
                  : "Sub Hire can't be approved"
              }`,
              color: "error",
            });
            this.$bus.$emit("showLoader", false);
          });
      }
    },

    getSubHireList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        status: "Completed",
        offset: this.offset,
        limit: this.itemsPerPage,
        company: this.currentProfile.company,
        ...params,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.subHire
        .getSubHireList(params)
        .then((res) => {
          this.subHireList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("subhire_completed");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getSubHireList();
      }
    }
  },
};
</script>
