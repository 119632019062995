<template>
  <FormComponent
    title="Add Driver"
    :backRoute="backRoute"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #formActions>
      <v-btn color="secondary" class="mr-2" depressed @click="resetForm">
        Reset
      </v-btn>
      <v-btn color="primary" depressed :disabled="!isValid" @click="submitForm">
        Submit
      </v-btn>
    </template>

    <template #formContent>
      <v-stepper v-model="stepper" non-linear flat>
        <v-stepper-header class="elevation-0">
          <v-stepper-step
            :rules="[() => (Object.keys(formErrors).length ? valid1 : true)]"
            step="1"
            editable
          >
            Basic Details
          </v-stepper-step>
          <v-divider class="ma-0" />

          <v-stepper-step
            :rules="[() => (Object.keys(formErrors).length ? valid2 : true)]"
            step="2"
            editable
          >
            Document Uploads
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1" class="pa-0">
            <v-form ref="stepperForm1" v-model="valid1">
              <v-row class="mx-2 my-0">
                <v-col cols="12" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">Basic Details</span>
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Driver Name *"
                    :rules="[(val) => !!val || 'Driver Name is Required!']"
                    required
                    hide-details="auto"
                    v-model="driverDetails.driver_name"
                    :error-messages="formErrors.driver_name"
                    @input="delete formErrors.driver_name"
                  />
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Depot *"
                    name="driver-form-depot"
                    :returnObject="false"
                    :items="allDepot"
                    itemText="depot_name"
                    itemValue="depot_name"
                    :rules="[(value) => !!value || 'Depot  is Required!']"
                    hide-details="auto"
                    v-model="driverDetails.depot"
                    :error-messages="formErrors.depot"
                    @input="delete formErrors.depot"
                  ></MultiSelect>
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <DateField
                    :inputProps="{
                      dense: true,
                      label: 'Date of Birth',
                      clearable: true,
                      'hide-details': 'auto',
                      'error-messages': formErrors.dob,
                    }"
                    :max="today"
                    v-model="driverDetails.dob"
                    @input="delete formErrors.dob"
                  />
                </v-col>
                <v-col cols="12" lg="3">
                  <v-row>
                    <v-col cols="12" lg="5" class="Form-Columns-Dense">
                      <MultiSelect
                        :items="allPhoneCodeList"
                        :rules="[(val) => !!val || 'Phone code is required!']"
                        label="Phone code *"
                        itemText="phone_code"
                        itemValue="phone_code"
                        hide-details="auto"
                        v-model="driverDetails.phone_code"
                        :error-messages="formErrors.phone_code"
                        @input="delete formErrors.phone_code"
                        class="Phone-Code-Class mr-2"
                      />
                    </v-col>

                    <v-col cols="12" lg="7" class="Form-Columns-Dense">
                      <InputField
                        type="number"
                        label="Contact Number *"
                        :rules="[
                          (val) => !!val || 'Contact Number is Required!',
                        ]"
                        required
                        hide-details="auto"
                        v-model="driverDetails.contact_number"
                        :error-messages="formErrors.contact_number"
                        @input="delete formErrors.contact_number"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="User Name *"
                    :rules="[(val) => !!val || 'User Name is Required!']"
                    required
                    hide-details="auto"
                    v-model="driverDetails.username"
                    :error-messages="formErrors.username"
                    @input="delete formErrors.username"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    v-model="driverDetails.employee_id"
                    label="Employee ID *"
                    hide-details="auto"
                    :rules="[(val) => !!val || 'Employee ID is Required!']"
                    :error-messages="formErrors.employee_id"
                    @input="delete formErrors.employee_id"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    :items="allDesignationList"
                    :rules="[(val) => !!val || 'Designation Type is Required!']"
                    label="Designation Type *"
                    itemText="display_name"
                    itemValue="value"
                    hide-details="auto"
                    v-model="driverDetails.designation"
                    :error-messages="formErrors.designation"
                    @input="delete formErrors.designation"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <DateField
                    :inputProps="{
                      dense: true,
                      label: 'Service Start Date *',
                      'hide-details': 'auto',
                      clearable: true,
                      rules: [
                        (val) => !!val || 'Service Start Date is Required!',
                      ],
                      'error-messages': formErrors.service_start_date,
                    }"
                    v-model="driverDetails.service_start_date"
                    @input="delete formErrors.service_start_date"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <DateField
                    :inputProps="{
                      dense: true,
                      label: 'Service End Date ',
                      clearable: true,
                      'hide-details': 'auto',
                      'error-messages': formErrors.service_end_date,
                    }"
                    v-model="driverDetails.service_end_date"
                    @input="delete formErrors.service_end_date"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    clearable
                    :items="allVehicleList"
                    label="Assigned Vehicle"
                    itemText="vehicle_number"
                    itemValue="id"
                    hide-details="auto"
                    v-model="driverDetails.assigned_vehicle"
                    :error-messages="formErrors.assigned_vehicle"
                    @input="delete formErrors.assigned_vehicle"
                  />
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">
                    Nationality Details
                  </span>
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    outlined
                    :items="allNationalityList"
                    v-model="driverDetails.nationality"
                    label="Nationality"
                    clearable
                    hide-details="auto"
                    :error-messages="formErrors.nationality"
                    @change="delete formErrors.nationality"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    v-model="driverDetails.national_id"
                    :rules="
                      driverDetails.nationality
                        ? [(val) => !!val || 'Nationality Id is required']
                        : []
                    "
                    :label="
                      driverDetails.nationality
                        ? 'Nationality Id *'
                        : 'Nationality Id'
                    "
                    hide-details="auto"
                    :error-messages="formErrors.national_id"
                    @input="delete formErrors.national_id"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <DateField
                    :inputProps="{
                      dense: true,
                      label: driverDetails.national_id
                        ? 'Nationality Expiry Date *'
                        : 'Nationality Expiry Date',
                      clearable: true,
                      'hide-details': 'auto',
                      rules: driverDetails.national_id
                        ? [
                            (val) =>
                              !!val || 'Nationality Expiry Date is required',
                          ]
                        : [],
                      'error-messages': formErrors.national_id_expiry,
                    }"
                    v-model="driverDetails.national_id_expiry"
                    @input="delete formErrors.national_id_expiry"
                  />
                </v-col>

                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="12" class="Form-Columns-Dense">
                      <span class="text-h6 font-weight-regular">
                        Passport Details
                      </span>
                    </v-col>
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <InputField
                        type="text"
                        v-model="driverDetails.passport_number"
                        label="Passport Number"
                        hide-details="auto"
                        :error-messages="formErrors.passport_number"
                        @input="delete formErrors.passport_number"
                      />
                    </v-col>
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <DateField
                        :inputProps="{
                          dense: true,
                          label: driverDetails.passport_number
                            ? `Passport Expiry Date *`
                            : `Passport Expiry Date`,
                          clearable: true,
                          'hide-details': 'auto',
                          'error-messages': formErrors.passport_expiry,
                          rules: driverDetails.passport_number
                            ? [
                                (val) =>
                                  !!val || 'Passport Expiry Date is required',
                              ]
                            : [],
                        }"
                        v-model="driverDetails.passport_expiry"
                        @input="delete formErrors.passport_expiry"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="12" class="Form-Columns-Dense">
                      <span class="text-h6 font-weight-regular">
                        Visa Details
                      </span>
                    </v-col>
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <InputField
                        type="text"
                        v-model="driverDetails.visa_number"
                        label="Visa Number"
                        hide-details="auto"
                        :error-messages="formErrors.visa_number"
                        @input="delete formErrors.visa_number"
                      />
                    </v-col>
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <DateField
                        :inputProps="{
                          dense: true,
                          label: driverDetails.visa_number
                            ? 'Visa Expiry Date *'
                            : 'Visa Expiry Date',
                          clearable: true,
                          'hide-details': 'auto',
                          'error-messages': formErrors.visa_expiry,
                          rules: driverDetails.visa_number
                            ? [(val) => !!val || 'Visa Expiry Date is required']
                            : [],
                        }"
                        :min="today"
                        v-model="driverDetails.visa_expiry"
                        @input="delete formErrors.visa_expiry"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">
                    License Details
                  </span>
                </v-col>
                <v-col cols="12" lg="6" class="Form-Columns-Dense">
                  <MultiSelect
                    :showAll="true"
                    label="License Types *"
                    :items="allLicenseTypeList"
                    itemText="display_name"
                    itemValue="value"
                    :multiple="true"
                    :chips="true"
                    :rules="[
                      (val) => !!val.length || 'License Type is required!',
                    ]"
                    hide-details="auto"
                    v-model="driverDetails.license_type"
                    :error-messages="formErrors.license_type"
                    @change="delete formErrors.license_type"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    v-model="driverDetails.license_number"
                    label="License Number *"
                    required
                    hide-details="auto"
                    :rules="[(val) => !!val || 'License Number is required!']"
                    :error-messages="formErrors.license_number"
                    @input="delete formErrors.license_number"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <DateField
                    :inputProps="{
                      dense: true,
                      label: 'License Expiry Date *',
                      clearable: true,
                      'error-messages': formErrors.license_expiry,
                      'hide-details': 'auto',
                      rules: [
                        (val) => !!val || 'License Expiry Date is required!',
                      ],
                    }"
                    v-model="driverDetails.license_expiry"
                    required
                    :min="today"
                    @input="delete formErrors.license_expiry"
                  />
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">
                    IMCO License Details
                  </span>
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    v-model="driverDetails.imco_license_number"
                    label="IMCO License Number"
                    hide-details="auto"
                    :error-messages="formErrors.imco_license_number"
                    @input="delete formErrors.imco_license_number"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <DateField
                    :inputProps="{
                      dense: true,
                      label: driverDetails.imco_license_number
                        ? 'IMCO Expiry Date *'
                        : 'IMCO Expiry Date',
                      clearable: true,
                      'hide-details': 'auto',
                      'error-messages': formErrors.imco_license_expiry,
                      rules: driverDetails.imco_license_number
                        ? [(val) => !!val || 'IMCO Expiry Date is required']
                        : [],
                    }"
                    v-model="driverDetails.imco_license_expiry"
                    @input="delete formErrors.imco_license_expiry"
                  />
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense mt-6">
                  <v-btn
                    color="primary"
                    @click="stepper = 2"
                    :disabled="!valid1"
                  >
                    Next
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2" class="pa-0">
            <v-form ref="stepperForm2" v-model="valid2">
              <v-row class="mx-2 my-0">
                <v-col cols="12" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">
                    Upload Documents
                  </span>
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    :items="documentTypes"
                    v-model="document_type"
                    label="Document Type"
                    hide-details="auto"
                    :error-messages="formErrors.document_type"
                    @input="delete formErrors.document_type"
                  />
                </v-col>
                <v-col cols="12" lg="6" class="Form-Columns-Dense">
                  <v-file-input
                    v-model="document"
                    label="Document"
                    :rules="[
                      (file) =>
                        !file ||
                        file.size < 2e6 ||
                        'File size should be less than 2 MB!',
                    ]"
                    accept="image/x-png,image/jpg,image/jpeg,application/pdf"
                    outlined
                    dense
                    hide-details="auto"
                    :error-messages="formErrors.document"
                    @change="delete formErrors.document, getFileData($event)"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <v-btn
                    color="primary"
                    depressed
                    :disabled="isDisabled"
                    @click="addDocument"
                  >
                    Add
                  </v-btn>
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense my-6">
                  <v-btn color="secondary" @click="stepper = 1">
                    Previous
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  class="Form-Columns-Dense"
                  v-show="driverNewDocuments && driverNewDocuments.length"
                >
                  <span class="text-h6 font-weight-regular">New Upload</span>

                  <v-card elevation="0" outlined>
                    <v-card-text class="Form-Columns-Dense">
                      <v-row
                        class="ma-0"
                        v-for="(data, j) in driverNewDocuments"
                        :key="j"
                      >
                        <v-col cols="12" lg="3" class="py-1">
                          <a :href="generateUrl(data.document)" target="_black">
                            <span>{{ data.document_type }}</span>
                          </a>
                        </v-col>

                        <v-col cols="12" lg="6" class="py-1">
                          <a :href="generateUrl(data.document)" target="_black">
                            <span>{{ data.document.name }}</span>
                          </a>
                        </v-col>

                        <v-col cols="12" lg="2" class="py-1">
                          <span>{{ getSize(data.document.size) }}</span>
                        </v-col>

                        <v-col cols="12" lg="1" class="text-right py-1">
                          <v-icon small color="primary" @click="removeFile(j)">
                            mdi-close
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </FormComponent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import TextareaField from "@/components/FormBaseComponents/TextareaField.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    InputField,
    SelectField,
    MultiSelect,
    TextareaField,
    FormComponent,
    DateField,
  },

  data() {
    return {
      driverDetails: {
        license_type: [],
      },
      driverNewDocuments: [],
      allPhoneCodeList: [],
      allDesignationList: [],
      allLicenseTypeList: [],
      allNationalityList: [],
      allVehicleList: [],
      allDepot: [],

      formErrors: {},

      stepper: "1",
      valid1: true,
      valid2: true,

      // fields for docs
      document_type: null,
      document: null,
      // Limitation on date input
      today: new Date().toISOString().slice(0, 10),
      documentTypes: [],

      backRoute: "/app/admin/asset-management/driver/list",
    };
  },
  computed: {
    isDisabled() {
      return !(this.document_type && this.document);
    },

    isValid() {
      return this.valid1 && this.valid2;
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return {};
      } else {
        return obj;
      }
    },
  },

  methods: {
    getDepotList(params = {}) {
      params = {
        limit: "all",
      };
      this.$api.depot
        .getDepotList(params)
        .then((res) => {
          this.allDepot = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    checkFileFormat(event) {
      const name = event.name;
      const lastDot = name.lastIndexOf(".");
      const ext = name.substring(lastDot + 1);
      let allowed = /(\jpg|\jpeg|\pdf|\png)$/i;
      if (!allowed.exec(ext)) {
        this.$nextTick(() => {
          this.document = null;
        });
        return false;
      } else {
        return true;
      }
    },
    getFileData(fileInput) {
      if (fileInput) {
        if (this.checkFileFormat(fileInput)) {
          if (fileInput && !this.document_type) {
            this.$nextTick(() => {
              this.document_type = null;
              this.document = null;
            });
            this.$bus.$emit("showToastMessage", {
              message: "Select Document type first!",
              color: "error",
            });
            return false;
          }
        } else {
          alert("This file format is not Supported!");
        }
      }
    },
    addDocument() {
      this.driverNewDocuments.push({
        document_type: this.document_type,
        document: this.document,
      });
      this.document = null;
      this.document_type = null;
    },
    removeFile(index) {
      this.driverNewDocuments.splice(index, 1);
    },
    generateUrl(file) {
      return URL.createObjectURL(file);
    },
    getSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    submitForm() {
      this.$bus.$emit("showLoader", true);

      this.$api.driver
        .addDriver(this.driverDetails)
        .then(async (res) => {
          if (this.driverNewDocuments.length > 0) {
            await this.uploadDriverDocuments(res.data.response_data.id);
          }
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Driver Created Successfully!",
            color: "success",
          });
          this.$router.push("/app/admin/asset-management/driver/list");
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't Create Driver!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
    async uploadDriverDocuments(id) {
      let i = 0;
      while (i < this.driverNewDocuments.length) {
        let payload = {
          id: id,
          data: null,
        };
        let newForm = new FormData();
        newForm.append(
          "document_type",
          this.driverNewDocuments[i].document_type
        );
        newForm.append("document", this.driverNewDocuments[i].document);
        payload.data = newForm;
        await this.$api.driver.uploadDriverDocument(payload);
        i++;
      }
    },

    resetForm() {
      this.driverDetails = {};
      this.$refs.stepperForm1.resetValidation();
      this.$refs.stepperForm2.resetValidation();
    },

    getDriverDocumentOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.driver
        .getDriverDocumentOptions()
        .then((res) => {
          this.documentTypes = res.data.actions.POST.document_type.choices.map(
            (obj) => {
              return {
                text: obj.display_name,
                value: obj.value,
              };
            }
          );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getPhoneCodeList() {
      this.$bus.$emit("showLoader", true);
      this.$api.country
        .getCountrySelectList({ limit: "all" })
        .then((res) => {
          this.allPhoneCodeList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getDriverOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.driver
        .getDriverOptions()
        .then((res) => {
          this.allLicenseTypeList =
            res.data.actions.POST.license_type.child.choices;
          this.allDesignationList = res.data.actions.POST.designation.choices;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getVehicleList() {
      this.$api.vehicle
        .getVehicleList({
          limit: "all",
          company: this.currentProfile.company,
        })
        .then((res) => {
          this.allVehicleList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    checkId() {
      if (this.$route.query.intId) {
        this.$bus.$emit("showLoader", true);
        this.$api.integration
          .getDriverIntegrationDetails(this.$route.query.intId)
          .then((res) => {
            this.backRoute = "/app/admin/integration/driver";
            this.driverDetails = res.data.request_body;

            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      }
    },
    getNationalityList() {
      this.$bus.$emit("showLoader", true);
      const params = {
        limit: "all",
      };
      this.$api.nationality
        .getNationalitySelectList(params)
        .then((res) => {
          this.allNationalityList = res.data.map((n) => n.nationality_name);
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      let url = localStorage.getItem("user_type").toLowerCase();
      if (url == "admin") {
        url = "admin";
      }
      this.$router.push(`/${url}`);
    } else {
      const permissions = getPermissions("driver");
      if (!permissions || !permissions.add) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        let url = localStorage.getItem("user_type").toLowerCase();
        if (url == "admin") {
          url = "admin";
        }
        this.$router.push(`/${url ? url : "error"}`);
      } else {
        this.getDriverDocumentOptions();
        this.getPhoneCodeList();
        this.getDriverOptions();
        this.getVehicleList();
        this.getNationalityList();
        this.checkId();
        this.getDepotList()
      }
    }
  },
};
</script>
