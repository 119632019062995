<template>
  <v-navigation-drawer
    v-model="drawer"
    :clipped="true"
    fixed
    app
    dark
    color="secondary"
    expand-on-hover
    :mini-variant="true"
    v-bind="$attrs"
    width="335px"
    class="app-nav-shadow Scrollbar"
  >
    <v-list dense shaped class="pr-1 pt-2 app-list-margin">
      <v-list-item v-for="(link, i) in nav_items" :key="i" class="pa-0">
        <v-list-item-group
          v-if="!link.subLinks"
          v-model="selectedItem"
          color="primary"
          class="Full-Width"
        >
          <v-list-item router exact :to="link.to">
            <v-list-item-action>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="link.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-list-group v-else class="py-1 Full-Width">
          <template v-slot:activator>
            <v-list-item-action>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="link.title" />
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(subLink, i) in link.subLinks"
            router
            exact
            :key="i"
            :to="subLink.to"
          >
            <v-list-item-action>
              <v-icon>{{ subLink.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="pl-6">
              <v-list-item-title v-text="subLink.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { getPermissions } from "@/utils/functions";

export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      selectedItem: 0,
      adminNav: [
        {
          icon: "mdi-view-dashboard",
          title: "Dashboard",
          to: "/app/admin/dashboard",
        },
        {
          icon: "mdi-bus-clock",
          title: "Trip Scheduling",
          permissionKey: "leg_trip",
          to: "/app/admin/trip/list",
        },
        {
          icon: "mdi-truck-delivery",
          title: "Delivery Advices",
          permissionKey: "delivery_advice",
          to: "/app/admin/delivery-advice/list",
        },
        {
          icon: "mdi-train-car-centerbeam-full",
          title: "Create Credit DA",
          permissionKey: "delivery_advice",
          to: "/app/admin/delivery-advice/direct-da-add",
        },
        {
          icon: "mdi-cash",
          title: "Create Cash DA",
          permissionKey: "delivery_advice",
          to: "/app/admin/delivery-advice/cash-da-add",
        },
        {
          icon: "mdi-inbox-multiple",
          title: "Order",
          permissionKey: "order",
          to: "/app/admin/order/list",
        },

        {
          icon: "mdi-finance",
          title: "Reports",
          permissionKey: "reports",
          to: "/app/admin/report",
        },
        {
          icon: "mdi-file-document",
          title: "Invoices",
          subLinks: [
            {
              icon: "mdi-file-document",
              title: "Invoice",
              permissionKey: "invoice",
              to: "/app/admin/invoice/customer/list",
            },
            {
              icon: "mdi-inbox-multiple",
              title: "Credit Note",
              permissionKey: "invoice",
              to: "/app/admin/invoice/credit-note/list",
            },
            {
              icon: "mdi-file-document",
              title: "Bulk Invoice Logs",
              permissionKey: "invoice",
              to: "/app/admin/invoice/customer/bulk-invoice-logs",
            },
          ],

          // permissionKey: "invoice",
          // to: "/app/admin/invoice/customer/list",
        },
        {
          icon: "mdi-finance",
          title: "Upload Lease Driver",

          to: "/app/admin/lease-driver/list",
        },
        {
          icon: "mdi-file-edit-outline",
          title: "Payment Receipt",
          permissionKey: "payment_receipt",
          to: "/app/admin/invoice/cash-receipt",
        },
        {
          icon: "mdi-truck-fast-outline",
          title: "Sub Hire",
          subLinks: [
            {
              icon: "mdi-circle-medium",
              title: "Created",
              permissionKey: "subhire_pending",
              to: "/app/admin/sub-hire/created",
            },
            {
              icon: "mdi-circle-medium",
              title: "Pending",
              permissionKey: "subhire_pending",
              to: "/app/admin/sub-hire/pending",
            },
            {
              icon: "mdi-circle-medium",
              title: "Approved",
              permissionKey: "subhire_approved",
              to: "/app/admin/sub-hire/approved",
            },
            {
              icon: "mdi-circle-medium",
              title: "Invoice Updated",
              permissionKey: "subhire_invoice_update",
              to: "/app/admin/sub-hire/invoice-updated",
            },
            {
              icon: "mdi-circle-medium",
              title: "Completed",
              permissionKey: "subhire_completed",
              to: "/app/admin/sub-hire/completed",
            },
            {
              icon: "mdi-circle-medium",
              title: "Cancelled",
              permissionKey: "subhire_completed",
              to: "/app/admin/sub-hire/cancelled",
            },
          ],
        },
        {
          icon: "mdi-gavel",
          title: "Bidding",
          to: "/app/admin/bidding/list",
        },
        {
          icon: "mdi-briefcase",
          title: "Customer Tariff",
          permissionKey: "contract",
          to: "/app/admin/customer-contract/list",
        },
        // {
        //   icon: "mdi-briefcase",
        //   title: "Transporter Tariff",
        //   permissionKey: "contract",
        //   to: "/app/admin/transporter-contract/list",
        // },
        {
          icon: "mdi-file-delimited",
          title: "Transporter RFQ",
          permissionKey: "contract",
          to: "/app/admin/lrfq/list",
        },
        {
          icon: "mdi-view-column",
          title: "Route Management",
          subLinks: [
            {
              icon: "mdi-map-marker-radius",
              title: "Location",
              permissionKey: "location",
              to: "/app/admin/lane-management/location/list",
              key: "location",
            },
            {
              icon: "mdi-map-marker-distance",
              title: "Routes",
              permissionKey: "lane",
              to: "/app/admin/lane-management/lane/list",
              key: "lane",
            },
            {
              icon: "mdi-truck",
              title: "Vehicle Types",
              permissionKey: "vehicle_type",
              to: "/app/admin/lane-management/vehicle-type",
              key: "vehicle_type",
            },
            {
              icon: "mdi-truck-trailer",
              title: "Trailer Types",
              permissionKey: "trailer_type",
              to: "/app/admin/lane-management/trailer-type",
              key: "trailer_type",
            },
            {
              icon: "mdi-train-car-container",
              title: "Container Category",
              permissionKey: "lane",
              to: "/app/admin/lane-management/container-category",
              key: "container_category",
            },
            {
              icon: "mdi-train-car-container",
              title: "Container Types",
              permissionKey: "container_type",
              to: "/app/admin/lane-management/container-type",
              key: "container_type",
            },
          ],
        },
        {
          icon: "mdi-text-box-check-outline",
          title: "Quotations",
          permissionKey: "quotation",
          to: "/app/admin/quotations/list",
        },
        {
          icon: "mdi-domain",
          title: "Company Management",
          subLinks: [
            {
              icon: "mdi-warehouse",
              title: "Customers",
              permissionKey: "customer",
              to: "/app/admin/company-management/customer/list",
              key: "customer",
            },
            {
              icon: "mdi-truck-flatbed",
              title: "Transporters",
              permissionKey: "transporter",
              to: "/app/admin/company-management/transporter/list",
              key: "transporter",
            },
          ],
        },
        {
          icon: "mdi-truck-fast",
          title: "Asset Management",
          subLinks: [
            {
              icon: "mdi-truck",
              title: "Vehicles",
              permissionKey: "vehicle",
              to: "/app/admin/asset-management/vehicle/list",
            },
            {
              icon: "mdi-truck-trailer",
              title: "Trailer",
              permissionKey: "trailer",
              to: "/app/admin/asset-management/trailer/list",
            },
            {
              icon: "mdi-account-circle",
              title: "Drivers",
              permissionKey: "driver",
              to: "/app/admin/asset-management/driver/list",
            },
          ],
        },
        {
          icon: "mdi-tune-variant",
          title: "Configuration",
          subLinks: [
            {
              icon: "mdi-circle-medium",
              title: "Depot",
              permissionKey: "configuration",
              to: "/app/admin/configuration/depot",
            },
            {
              icon: "mdi-circle-medium",
              title: "Branch",
              permissionKey: "configuration",
              to: "/app/admin/configuration/branch",
            },
            {
              icon: "mdi-circle-medium",
              title: "Edit Company",
              permissionKey: "configuration",
              to: "/app/admin/configuration/company-edit",
            },
            {
              icon: "mdi-circle-medium",
              title: "Master",
              permissionKey: "configuration",
              to: "/app/admin/configuration/master",
            },
            {
              icon: "mdi-circle-medium",
              title: "Country",
              permissionKey: "configuration",
              to: "/app/admin/configuration/country",
            },
            {
              icon: "mdi-circle-medium",
              title: "Nationality",
              permissionKey: "configuration",
              to: "/app/admin/configuration/nationality",
            },
            {
              icon: "mdi-circle-medium",
              title: "Currency",
              permissionKey: "configuration",
              to: "/app/admin/configuration/currency",
            },
            {
              icon: "mdi-circle-medium",
              title: "Milestone",
              permissionKey: "configuration",
              to: "/app/admin/configuration/milestone",
            },
            {
              icon: "mdi-circle-medium",
              title: "Charge",
              permissionKey: "configuration",
              to: "/app/admin/configuration/charge",
            },
            {
              icon: "mdi-circle-medium",
              title: "Email Notification",
              permissionKey: "configuration",
              to: "/app/admin/configuration/email-subscriber",
            },
            {
              icon: "mdi-circle-medium",
              title: "Check List",
              permissionKey: "configuration",
              to: "/app/admin/configuration/check-list",
            },
            {
              icon: "mdi-circle-medium",
              title: "Department",
              permissionKey: "configuration",
              to: "/app/admin/configuration/department",
            },
            {
              icon: "mdi-circle-medium",
              title: "UN Class",
              permissionKey: "configuration",
              to: "/app/admin/configuration/un-class",
            },
            {
              icon: "mdi-circle-medium",
              title: "IMO Class",
              permissionKey: "configuration",
              to: "/app/admin/configuration/imo-class",
            },
            {
              icon: "mdi-circle-medium",
              title: "ADR Class",
              permissionKey: "configuration",
              to: "/app/admin/configuration/adr-class",
            },
            {
              icon: "mdi-circle-medium",
              title: "Bank Details",
              permissionKey: "configuration",
              to: "/app/admin/configuration/bank",
            },
            {
              icon: "mdi-circle-medium",
              title: "Terms & Condtion",
              permissionKey: "configuration",
              to: "/app/admin/configuration/terms_condition",
            },
          ],
        },

        {
          icon: "mdi-account-details",
          title: "Role Management",
          permissionKey: "role",
          to: "/app/admin/role-management/list",
          key: "role",
        },
        {
          icon: "mdi-account-check",
          title: "User Management",
          permissionKey: "user",
          to: "/app/admin/user-management/list",
          key: "user",
        },
        // {
        //   icon: "mdi-api",
        //   title: "Integrations",
        //   subLinks: [
        //     {
        //       icon: "mdi-circle-medium",
        //       title: "Company",
        //       permissionKey: "integration",
        //       to: "/app/admin/integration/company",
        //       key: "customer_log",
        //     },
        //     {
        //       icon: "mdi-circle-medium",
        //       title: "Driver",
        //       permissionKey: "integration",
        //       to: "/app/admin/integration/driver",
        //       key: "driver_log",
        //     },
        //     {
        //       icon: "mdi-circle-medium",
        //       title: "Driver Leave",
        //       permissionKey: "integration",
        //       to: "/app/admin/integration/driver-leave",
        //       key: "driver_leave_log",
        //     },
        //     {
        //       icon: "mdi-circle-medium",
        //       title: "Asset Maintenance",
        //       permissionKey: "integration",
        //       to: "/app/admin/integration/asset-maintenance",
        //       key: "asset_maintenance_log",
        //     },
        //   ],
        // },
        {
          icon: "mdi-api",
          title: "Integration Logs",
          subLinks: [
            {
              icon: "mdi-circle-medium",
              title: "Inbound Integrations APIs",
              permissionKey: "integration",
              to: "/app/admin/integration-logs/inbound-integration-api",
            },
            {
              icon: "mdi-circle-medium",
              title: "Outbound Integration APIs",
              permissionKey: "integration",
              to: "/app/admin/integration-logs/outbound-integration-api",
            },
          ],
        },
        {
          icon: "mdi-ticket",
          title: "Support",
          permissionKey: "support_ticket",
          to: "/app/admin/ticket/list",
        },
      ],
      customerNav: [
        {
          icon: "mdi-view-dashboard",
          title: "Dashboard",
          to: "/app/customer/dashboard",
        },
        {
          icon: "mdi-inbox-multiple",
          title: "Order",
          permissionKey: "order",
          to: "/app/customer/order/list",
        },
        {
          icon: "mdi-briefcase",
          title: "Tariff",
          to: "/app/customer/contract",
        },
        {
          icon: "mdi-file-document",
          title: "Invoices",
          permissionKey: "invoice",
          to: "/app/customer/invoice/list",
        },
        {
          icon: "mdi-notebook",
          title: "Address Book",
          permissionKey: "address_book",
          to: "/app/customer/address-book",
          key: "address_book",
        },
        {
          icon: "mdi-view-list",
          title: "Rates",
          subLinks: [
            {
              icon: "mdi-circle-medium",
              title: "Rate Enquiry",
              permissionKey: "rate_enquiry",
              to: "/app/customer/rate/spot",
            },
            {
              icon: "mdi-circle-medium",
              title: "Quotation",
              permissionKey: "quotation",
              to: "/app/customer/rate/quotation/list",
            },
            // {
            //   icon: "mdi-circle-medium",
            //   title: "Terms & Condtion",
            //   permissionKey: "quotation",
            //   to: "/app/customer/rate/terms_condition",
            // },
          ],
        },
        {
          icon: "mdi-account-check",
          title: "User Management",
          permissionKey: "user",
          to: "/app/customer/user-management/list",
          key: "user",
        },
        // {
        //   icon: "mdi-account-details",
        //   title: "Role Management",
        //   permissionKey: "role",
        //   to: "/app/customer/role-management/list",
        //   key: "role",
        // },
      ],
      transporterNav: [
        {
          icon: "mdi-view-dashboard",
          title: "Dashboard",
          to: {
            name: "app-transporter-dashboard",
          },
        },
        // {
        //   icon: "mdi-bus-clock",
        //   title: "Leg/Trip Scheduling",
        //   permissionKey: "leg_trip",
        //   to: "/transporter/trip_scheduling/list/",
        // },
        // {
        //   icon: "mdi-bus-marker",
        //   title: "Trip List",
        //   to: "/transporter/trips/list",
        // },
        // {
        //   icon: "mdi-briefcase",
        //   title: "Tariff",
        //   to: "app/transporter/contract",
        // },
        {
          icon: "mdi-briefcase",
          title: "Bidding",
          to: {
            name: "app-transporter-bidding-list",
          },
        },
        {
          icon: "mdi-file-delimited",
          title: "LRFQ",

          to: {
            name: "app-transporter-lrfq-list",
          },
        },
        {
          icon: "mdi-truck-fast-outline",
          title: "Sub Hire Jobs",

          to: {
            name: "app-transporter-subhirejobs-list",
          },
        },
        // {
        //   icon: "mdi-receipt",
        //   title: "Invoices",
        //   permissionKey: "invoice",
        //   to: "/transporter/invoices/list",
        // },
        // {
        //   icon: "mdi-lock-question",
        //   title: "Long Term RFQ",
        //   permissionKey: "lrfq",
        //   to: "/transporter/lrfq/list",
        // },
        // {
        //   icon: "mdi-truck-fast",
        //   title: "Asset Management",
        //   subLinks: [
        //     {
        //       icon: "mdi-circle-medium",
        //       title: "Vehicles",
        //       permissionKey: "vehicle",
        //       to: "/transporter/vehicle/list",
        //     },
        //     {
        //       icon: "mdi-circle-medium",
        //       title: "Trailer",
        //       permissionKey: "trailer",
        //       to: "/transporter/trailer/list",
        //     },
        //     {
        //       icon: "mdi-circle-medium",
        //       title: "Drivers",
        //       permissionKey: "driver",
        //       to: "/transporter/driver/list",
        //     },
        //   ],
        // },
        // {
        //   icon: "mdi-api",
        //   title: "Integrations",
        //   subLinks: [
        //     {
        //       icon: "mdi-circle-medium",
        //       title: "Driver",
        //       permissionKey: "integration",
        //       to: "/transporter/integration/driver",
        //       key: "driver_log",
        //     },
        //     {
        //       icon: "mdi-circle-medium",
        //       title: "Driver Leave",
        //       permissionKey: "integration",
        //       to: "/transporter/integration/driver_leave",
        //       key: "driver_leave_log",
        //     },
        //     {
        //       icon: "mdi-circle-medium",
        //       title: "Asset Maintenance",
        //       permissionKey: "integration",
        //       to: "/transporter/integration/asset_maintenance",
        //       key: "asset_maintenance_log",
        //     },
        //   ],
        // },
        // {
        //   icon: "mdi-account-details",
        //   title: "Role Management",
        //   permissionKey: "role",
        //   to: "/transporter/transporter_role_management/list",
        //   key: "role",
        // },
        // {
        //   icon: "mdi-account-check",
        //   title: "User Management",
        //   permissionKey: "user",
        //   to: "/transporter/user_management/list",
        //   key: "user",
        // },s
      ],
      userType: null,
    };
  },
  computed: {
    drawer: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    items() {
      if (this.userType == "Customer") {
        return this.customerNav;
      }
      if (this.userType == "Admin") {
        return this.adminNav;
      }
      if (this.userType == "Transporter") {
        return this.transporterNav;
      }
    },
    nav_items() {
      return this.filterNav(this.items);
    },
  },
  methods: {
    filterNav(navList) {
      return navList && Array.isArray(navList)
        ? navList.filter((navItem) => {
            if (navItem.subLinks && Array.isArray(navItem.subLinks)) {
              navItem.subLinks = this.filterNav(navItem.subLinks);
              if (navItem.subLinks.length == 0) {
                return false;
              }
            }
            if (!navItem.permissionKey) {
              return true;
            }
            const permission = getPermissions(navItem.permissionKey);
            return permission
              ? Object.values(permission).indexOf(true) > -1
              : false;
          })
        : [];
    },
  },
  mounted() {
    let user_type = localStorage.getItem("user_type");

    if (!user_type) {
      this.$router.push("/");
    } else {
      this.userType = user_type;
    }
  },
};
</script>

<style scoped>
.mdi-10px {
  font-size: 10px;
}
.v-list-item .v-list-item__title,
.app-list-margin .v-list-item__subtitle {
  font-size: 15px !important;
}
</style>
