import { render, staticRenderFns } from "./TransporterContractDetails.vue?vue&type=template&id=31ed2105&scoped=true"
import script from "./TransporterContractDetails.vue?vue&type=script&lang=js"
export * from "./TransporterContractDetails.vue?vue&type=script&lang=js"
import style0 from "./TransporterContractDetails.vue?vue&type=style&index=0&id=31ed2105&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31ed2105",
  null
  
)

export default component.exports