<template>
  <v-dialog max-width="85%" v-model="showChargeForm">
    <v-card>
      <v-card-title class="secondary white--text px-4 py-2">
        <span class="text-capitalize">
          Delivery Advice Charge(s) for
          {{ this.daDetails ? this.daDetails.da_no : "" }}
        </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), (showChargeForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form ref="chargeForm" v-if="showChargeForm" v-model="isValid">
          <v-row class="my-0">
            <v-col cols="12" lg="2" class="Form-Columns">
              <MultiSelect
                label="Select Charge*"
                :items="allChargeList"
                itemText="charge_title"
                itemValue="id"
                hide-details="auto"
                :rules="[(val) => !!val || 'Check Type is required!']"
                returnObject
                v-model="deliveryAdviceChargeDetails.charge"
                :error-messages="formErrors.charge"
                @change="
                  delete formErrors.charge;
                  setChargeDetails();
                "
              />
            </v-col>
            <v-col cols="12" lg="3" class="Form-Columns">
              <InputField
                label="Description"
                readonly
                hide-details="auto"
                :clearable="false"
                v-model="deliveryAdviceChargeDetails.description"
                :error-messages="formErrors.description"
              />
            </v-col>

            <v-col cols="12" lg="1" class="Form-Columns">
              <InputField
                label="Revenue GL No."
                readonly
                hide-details="auto"
                :clearable="false"
                v-model="deliveryAdviceChargeDetails.gl_no"
                :error-messages="formErrors.gl_no"
              />
            </v-col>

            <v-col cols="12" lg="1" class="Form-Columns">
              <InputField
                type="number"
                :label="`Rate (${$globals.currency}) *`"
                required
                :rules="[(val) => !!val || 'Rate is required!']"
                hide-details="auto"
                v-model="deliveryAdviceChargeDetails.rate"
                :error-messages="formErrors.rate"
                @input="
                  delete formErrors.rate;

                  chargeDataChanged();
                "
              />
            </v-col>
            <v-col cols="12" lg="1" class="pa-2">
              <InputField
                readonly
                type="number"
                hide-details="auto"
                :clearable="false"
                v-model="deliveryAdviceChargeDetails.tax_percentage"
                label="TAX (%)"
                :disabled="!deliveryAdviceChargeDetails.tax_percentage"
                :error-messages="formErrors.tax_percentage"
                @input="delete formErrors.tax_percentage"
              />
            </v-col>
            <v-col cols="2" class="Form-Columns-Dense">
              <InputField
                hide-details="auto"
                type="number"
                :label="`Tax Amount (${$globals.currency})`"
                :clearable="false"
                readonly
                :value="deliveryAdviceChargeDetails.tax"
              />
            </v-col>
            <v-col cols="2" class="Form-Columns-Dense">
              <InputField
                hide-details="auto"
                type="number"
                :label="`Amount (${$globals.currency})`"
                :clearable="false"
                readonly
                :value="deliveryAdviceChargeDetails.amount_with_tax"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          {{ editMode ? "Update" : "Add" }}
        </v-btn>

        <v-btn class="ml-3" color="secondary" depressed @click="resetForm">
          {{ editMode ? "Cancel" : "Reset" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";

export default {
  components: {
    InputField,
    SelectField,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    daDetails: {
      default: false,
    },
    chargeDetails: {
      required: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getChargeList();
        if (this.editMode) {
          this.checkIfEdit();
        }
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showChargeForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      formErrors: {},
      isValid: true,
      deliveryAdviceChargeDetails: {},
      allChargeList: [],
    };
  },
  methods: {
    chargeDataChanged() {
      this.calculateRate(this.deliveryAdviceChargeDetails);
    },
    checkIfEdit() {
      this.deliveryAdviceChargeDetails = { ...this.chargeDetails };
      this.setChargeDetails();
    },
    setChargeDetails() {
      if (this.deliveryAdviceChargeDetails.charge) {
        const c = { ...this.deliveryAdviceChargeDetails.charge };
        delete c.id;
        this.deliveryAdviceChargeDetails = {
          ...this.deliveryAdviceChargeDetails,
          ...c,
        };
        this.chargeDataChanged();
      }
    },
    calculateRate(charge) {
      const rate = charge.rate;
      const tax = charge.tax_percentage;
      const quantity = 1;

      if (rate && quantity) {
        const totalGrossRate = parseFloat(rate) * parseFloat(quantity);
        if (totalGrossRate && tax) {
          const totalTaxAmount = totalGrossRate * (parseFloat(tax) / 100);

          this.deliveryAdviceChargeDetails.tax = totalTaxAmount.toFixed(4);
          this.deliveryAdviceChargeDetails.amount_with_tax = (
            totalGrossRate + totalTaxAmount
          ).toFixed(4);
        }
      } else {
        this.deliveryAdviceChargeDetails = {
          ...this.deliveryAdviceChargeDetails,
          amount_with_tax: 0.0,
          tax: 0.0,
        };
      }
    },
    submitForm() {
      this.$bus.$emit("showLoader", true);
      this.formErrors = {};

      let params = {
        ...this.deliveryAdviceChargeDetails,
        charge:
          typeof this.deliveryAdviceChargeDetails.charge == typeof {}
            ? this.deliveryAdviceChargeDetails.charge.id
            : this.deliveryAdviceChargeDetails.charge,
      };

      if (!this.editMode) {
        params.delivery_advice = this.daDetails.id;
        this.$api.deliveryAdviceCharge
          .addDeliveryAdviceCharge(params)
          .then(() => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Delivery Charge added successfully!",
              color: "success",
            });
            this.resetForm();
            this.showChargeForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't add delivery charge!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        this.$api.deliveryAdviceCharge
          .updateDeliveryAdviceCharge({
            id: this.deliveryAdviceChargeDetails.id,
            data: params,
          })
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Delivery Charge updated successfully!",
              color: "success",
            });
            this.resetForm();
            this.showChargeForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't update delivery charge!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      }
    },

    getChargeList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
        is_active: "true",
      };
      this.$api.charge
        .getChargeList(params)
        .then((res) => {
          this.allChargeList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    resetForm() {
      this.deliveryAdviceChargeDetails = {};
      if (this.$refs.chargeForm) {
        this.$refs.chargeForm.reset();
      }
      if (this.editMode) {
        this.showChargeForm = false;
      }
    },
  },
};
</script>
