import ContainerCategoryList from "@/pages/admin/lane-management/ContainerCategoryList.vue";

const containerCategoryRoutes = [
  {
    path: "container-category",
    name: "app-admin-lane-management-container-category",
    component: ContainerCategoryList,
  },
]

export default containerCategoryRoutes