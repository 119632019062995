<template>
  <FormComponent
    title="Add LRFQ"
    :backRoute="backRoute"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #formActions>
      <v-btn color="secondary" class="ml-2 mr-2" depressed @click="resetForm">
        Reset
      </v-btn>
      <v-btn
        v-if="!lrfqList.length"
        color="primary"
        depressed
        @click="generateContracts"
        :disabled="
          !isValid ||
          !lrfqDetails.lane.length ||
          !lrfqDetails.container_type.length ||
          !lrfqDetails.transporter.length
        "
      >
        Next
      </v-btn>
      <v-btn
        v-else
        color="primary"
        depressed
        @click="submitForm"
        :disabled="!allFormValidation"
      >
        Submit
      </v-btn>
    </template>

    <template #formContent>
      <v-row>
        <v-form ref="customerContract" v-model="isValid">
          <v-card class="rounded-lg px-2 py-2" flat>
            <v-row class="mx-2 my-0">
              <v-col cols="12" class="Form-Columns-Dense">
                <span class="text-h6 font-weight-regular pl-2">
                  Tariff Details
                </span>
              </v-col>
              <v-col cols="12" lg="3" class="Form-Columns-Dense">
                <MultiSelect
                  hide-details="auto"
                  :items="allContainerCategoryList"
                  itemText="name"
                  itemValue="id"
                  v-model="lrfqDetails.container_category"
                  :rules="[(val) => !!val || 'Container Category is Required!']"
                  label="Select Container Category *"
                  required
                  :error-messages="formErrors.container_category"
                  @input="
                    delete formErrors.container_category,
                      onContainerCategoryChange()
                  "
                ></MultiSelect>
              </v-col>
              <v-col cols="12" lg="3" class="Form-Columns-Dense">
                <MultiSelect
                  :showAll="true"
                  label="Select Transporter(s)"
                  :items="transporterList"
                  :returnObject="true"
                  itemText="company_name"
                  itemValue="id"
                  hide-details="auto"
                  :rules="[(val) => !!val.length || 'Transporter is required!']"
                  :multiple="true"
                  :chips="true"
                  chipWidthStyle="max-width: 3.7vw;"
                  v-model="lrfqDetails.transporter"
                  :error-messages="formErrors.transporter"
                  @change="delete formErrors.transporter"
                >
                </MultiSelect>
              </v-col>
              <v-col cols="12" lg="3" class="Form-Columns-Dense">
                <DateField
                  :inputProps="{
                    dense: true,
                    label: 'Last Submission Date *',
                    'hide-details': 'auto',
                    rules: [
                      (val) => !!val || 'Last Submission Date is required!',
                    ],
                    'error-messages': formErrors.end_date,
                  }"
                  :min="today"
                  :max="maxDate"
                  required
                  v-model="lrfqDetails.last_submission_date"
                  @input="delete formErrors.end_date"
                />
              </v-col>
              <v-col cols="12" lg="3" class="Form-Columns-Dense">
                <DateField
                  :inputProps="{
                    dense: true,
                    label: ' Tariff Start Date *',
                    'hide-details': 'auto',
                    rules: [(val) => !!val || 'Start Date is required!'],
                    'error-messages': formErrors.start_date,
                  }"
                  required
                  :min="calculateMinDate()"
                  v-model="lrfqDetails.start_date"
                  @input="delete formErrors.start_date"
                />
              </v-col>
              <v-col cols="12" lg="3" class="Form-Columns-Dense">
                <DateField
                  :inputProps="{
                    dense: true,
                    label: 'Tariff End Date *',
                    'hide-details': 'auto',
                    rules: [(val) => !!val || 'End Date is required!'],
                    'error-messages': formErrors.end_date,
                  }"
                  required
                  :min="lrfqDetails.start_date"
                  v-model="lrfqDetails.end_date"
                  @input="delete formErrors.end_date"
                />
              </v-col>

              <!-- <v-col cols="12" lg="3" class="Form-Columns-Dense">
              <Autocomplete
                :loading="listLoading"
                :totalItems="laneCount"
                :items="allLaneList"
                @updateList="getLaneList"
                label="Route *"
                :multiple="true"
                itemText="title"
                itemValue="id"
                :rules="[(v) => !!v || 'Route is Required']"
                hide-details="auto"
                v-model="lrfqDetails.lane"
                :error-messages="formErrors.lane"
                @input="
                  delete formErrors.lane;
                  delete formErrors.job_type;
                "
              />
            </v-col> -->
              <v-col cols="12" lg="3" class="Form-Columns">
                <MultiSelect
                  label="Route *"
                  :multiple="true"
                  :items="allLaneList"
                  :returnObject="true"
                  itemText="title"
                  itemValue="id"
                  :rules="[(v) => !!v || 'Route is Required']"
                  v-model="lrfqDetails.lane"
                  :error-messages="formErrors['lane']"
                  @change="
                    (formErrors['lane'] = ''), (formErrors['job_type'] = '')
                  "
                />
              </v-col>
              <v-col cols="12" lg="3" class="Form-Columns-Dense">
                <MultiSelect
                  label="Container Type *"
                  :items="allContainersType"
                  :multiple="true"
                  :returnObject="true"
                  itemText="title"
                  itemValue="id"
                  :rules="[(v) => !!v || 'Container Type is Required']"
                  hide-details="auto"
                  v-model="lrfqDetails.container_type"
                  :error-messages="formErrors.container_type"
                  @input="delete formErrors.container_type"
                />
              </v-col>
              <v-col cols="12" lg="3" class="Form-Columns-Dense">
                <MultiSelect
                  label="Container Count *"
                  :items="allContainersCount"
                  itemText="title"
                  itemValue="id"
                  :rules="[(v) => !!v || 'Container Count is Required']"
                  hide-details="auto"
                  v-model="lrfqDetails.container_count"
                  :error-messages="formErrors.container_count"
                  @input="delete formErrors.container_count"
                />
              </v-col>

              <v-col cols="12" lg="3" class="Form-Columns-Dense">
                <MultiSelect
                  label="Job Type *"
                  :items="allJobType"
                  itemText="display_name"
                  itemValue="value"
                  :rules="[(v) => !!v || 'Job Type is Required']"
                  hide-details="auto"
                  v-model="lrfqDetails.job_type"
                  :error-messages="formErrors.job_type"
                  @input="delete formErrors.job_type"
                />
              </v-col>

              <v-col cols="12" lg="6" class="Form-Columns-Dense">
                <InputField
                  type="text"
                  label="Admin Remarks"
                  hide-details="auto"
                  v-model="lrfqDetails.admin_remarks"
                  :error-messages="formErrors.admin_remarks"
                  @input="delete formErrors.admin_remarks"
                />
              </v-col>
            </v-row>
          </v-card>

          <v-row v-if="lrfqList.length != 0">
            <v-col cols="12" lg="12" class="mb-0 pr-2">
              <v-card class="rounded-lg pa-1" elevation="0">
                <v-card-text class="pa-0">
                  <v-row class="ma-0">
                    <v-col cols="12" class="Form-Columns">
                      <v-data-table
                        :headers="headers"
                        :items="lrfqList"
                        :items-per-page="-1"
                        disable-pagination
                        hide-default-footer
                      >
                        <template #[`item.actions`]="{ index }">
                          <v-btn
                            color="primary"
                            small
                            depressed
                            @click="removeContract(index)"
                            class="ml-1 mr-2"
                          >
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-row>
    </template>
  </FormComponent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import Autocomplete from "@/components/FormBaseComponents/Autocomplete.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    DateField,
    InputField,
    Autocomplete,
    MultiSelect,
    FormComponent,
  },

  data() {
    return {
      lrfqDetails: {
        lane: [],
        container_type: [],
        transporter: [],
        container_category: null,
      },
      allContainersCount: [1, 2, 3, 4],
      formErrors: {},
      lrfqList: [],
      allContainerCategoryList: [],

      transporterList: [],
      allContainersType: [],
      allJobType: [],
      allLaneList: [],

      isValid: true,

      today: new Date().toISOString().slice(0, 10),
      keyUpdate: 0,
      backRoute: {
        path: "/app/admin/lrfq/list",
      },
      listLoading: false,
      laneCount: 0,
      headers: [
        {
          text: "Transporter",
          value: "transporter.company_name",
          sortable: false,
          class:
            "heading rounded-lg rounded-r-0 rounded-b-0 text-subtitle-1 px-4 py-2 font-weight-medium",
        },
        {
          text: "Lane",
          value: "lane.title",
          sortable: false,
          class:
            "heading rounded-lg rounded-r-0 rounded-b-0 text-subtitle-1 px-4 py-2 font-weight-medium",
        },
        {
          text: "Container Type",
          value: "container_type.type_name",
          sortable: false,
          class:
            "heading rounded-lg rounded-r-0 rounded-b-0 text-subtitle-1 px-4 py-2 font-weight-medium",
        },
        {
          text: "Container Count",
          value: "container_count",
          sortable: false,
          class:
            "heading rounded-lg rounded-r-0 rounded-b-0 text-subtitle-1 px-4 py-2 font-weight-medium",
        },
        {
          text: "Job Type",
          value: "job_type",
          sortable: false,
          class:
            "heading rounded-lg rounded-r-0 rounded-b-0 text-subtitle-1 px-4 py-2 font-weight-medium",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class:
            "heading rounded-lg rounded-l-0 rounded-b-0 text-subtitle-1 px-4 py-2 font-weight-medium",
        },
      ],
    };
  },
  computed: {
    allFormValidation() {
      return this.isValid;
    },
    filteredTransporters() {
      // Filter transporters based on selected container category

      if (!this.lrfqDetails.container_category) return this.transporterList;
      const categoryTransporters = this.transporterList.filter(
        (transporter) =>
          transporter.container_category === this.lrfqDetails.container_category
      );
      return categoryTransporters;
    },
    maxDate() {
      if (this.lrfqDetails.start_date) {
        const startDate = new Date(this.lrfqDetails.start_date);
        startDate.setDate(startDate.getDate() - 1);
        return startDate.toISOString().split("T")[0];
      }
      return null;
    },
  },
  methods: {
    async onContainerCategoryChange() {
      await this.getTransporterList();
      await this.getContainersType(); // Call getContainersType when container category changes
      this.lrfqDetails.transporter = this.filteredTransporters;
    },
    getContainerCategoryList(params = {}) {
      this.$bus.$emit("showLoader", true);

      params = {
        ...params,
        limit: "all",
      };

      this.$api.containerCategory
        .getContainerCategoryList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allContainerCategoryList = res.data;
          this.totalItems = res.count;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    calculateMinDate() {
      if (this.lrfqDetails.last_submission_date) {
        // Add one day to the last_submission_date
        const minDate = new Date(this.lrfqDetails.last_submission_date);
        minDate.setDate(minDate.getDate() + 1);
        return minDate.toISOString().substr(0, 10);
      }
      return null; // or a default minimum date if last_submission_date is not set
    },
    generateContracts() {
      if (
        this.lrfqDetails.lane.length &&
        this.lrfqDetails.container_type.length &&
        this.lrfqDetails.transporter.length
      ) {
        let arrayToPush = [];
        this.lrfqList = [];
        this.$bus.$emit("showLoader", true);
        for (
          let vendorIndex = 0;
          vendorIndex < this.lrfqDetails.transporter.length;
          vendorIndex++
        ) {
          const transporter = this.lrfqDetails.transporter[vendorIndex];
          for (
            let laneIndex = 0;
            laneIndex < this.lrfqDetails.lane.length;
            laneIndex++
          ) {
            const lane = this.lrfqDetails.lane[laneIndex];
            for (
              let productIndex = 0;
              productIndex < this.lrfqDetails.container_type.length;
              productIndex++
            ) {
              const container_type =
                this.lrfqDetails.container_type[productIndex];
              arrayToPush.push({
                // ...this.lrfqDetails,
                lane: lane,
                container_type: container_type,
                transporter: transporter,
              });
            }
          }
        }
        arrayToPush.forEach((obj) => {
          obj.container_count = this.lrfqDetails.container_count;
          obj.job_type = this.lrfqDetails.job_type;
        });

        this.lrfqList = arrayToPush;
        this.$bus.$emit("showLoader", false);
      }
    },

    removeContract(index) {
      this.lrfqList.splice(index, 1);
    },
    checkRateErrors() {
      if (
        this.formErrors &&
        this.formErrors.customer_contract_rates &&
        (this.formErrors.customer_contract_rates.length == 0 ||
          this.formErrors.customer_contract_rates.every(
            (obj) => Object.keys(obj).length == 0
          ))
      ) {
        delete this.formErrors.customer_contract_rates;
      }
    },
    checkChargeErrors() {
      if (
        this.formErrors &&
        this.formErrors.customer_contract_charges &&
        (this.formErrors.customer_contract_charges.length == 0 ||
          this.formErrors.customer_contract_charges.every(
            (obj) => Object.keys(obj).length == 0
          ))
      ) {
        delete this.formErrors.customer_contract_charges;
      }
    },
    getLaneList(params = {}) {
      this.listLoading = true;
      params = {
        is_active: true,
        limit: "all",
        ...params,
      };

      this.$api.lane
        .getLaneSelectList(params)
        .then((res) => {
          this.listLoading = false;
          if ("count" in res) {
            this.laneCount = res.count;
          }

          if (params.offset == 0) {
            this.allLaneList = res.data;
          } else {
            this.allLaneList.push(...res.data);
          }
        })
        .catch((err) => {
          this.listLoading = false;
        });
    },

    async generatePayload() {
      let payload = [];
      let index = 0;
      while (payload.length !== this.lrfqList.length) {
        let data = { ...this.lrfqList[index] };
        index++;

        data = {
          transporter: data.transporter.id,
          lane: data.lane.id,
          container_type: data.container_type.id,
          container_count: data.container_count,
          job_type: data.job_type,
        };

        payload.push(data);
      }
      return payload;
    },

    async submitForm() {
      this.$bus.$emit("showLoader", true);
      let data = {
        ...this.lrfqDetails,
        total_transporter: this.lrfqDetails.transporter.length,
        total_container_type: this.lrfqDetails.container_type.length,
      };

      if (this.lrfqList && this.lrfqList.length) {
        data.lrfq_request = await this.generatePayload();
      }

      delete data.transporter;
      delete data.lane;
      delete data.container_type;
      this.$api.lrfq
        .bulkAddLrfq(data)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Lrfq added Successfully!",
            color: "success",
          });

          this.showDialog = false;
          this.$router.push("/app/admin/lrfq/list");
          this.$emit("refreshList");
        })
        .catch((err) => {
          this.formErrors = err.data.error.error;

          this.$bus.$emit("showLoader", false);
        });
    },

    resetForm() {
      if (this.$refs.customerContract) {
        this.$refs.customerContract.reset();
      }
      this.lrfqDetails = {
        created_from: "Admin",
        customer_contract_rates: [{}],
        customer_contract_charges: [],
      };
    },

    getContainersType() {
      this.$bus.$emit("showLoader", true);
      const params = {
        limit: "all",
        container_category: this.lrfqDetails.container_category,
      };
      this.$api.containerType
        .getContainerTypeSelectList(params)
        .then((res) => {
          this.allContainersType = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },

    getCustomerContractOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.customerContract
        .getCustomerContractOptions()
        .then((res) => {
          this.allJobType = res.data.actions.POST.job_type.choices;
          this.$bus.$emit("showLoader", false);
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },
    async getTransporterList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company_type: "Transporter",
        container_category: this.lrfqDetails.container_category,
        limit: "all",
        ...params,
      };
      try {
        const res = await this.$api.company
          .getCompanyList(params)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.transporterList = res.data;
          });
      } catch (error) {
        console.error(error);
        this.$bus.$emit("showLoader", false);
      }
    },

    getCustomerContractObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.customerContract
        .getCustomerContractObject(id)
        .then((res) => {
          this.lrfqDetails = res?.data || {};
          const lane = res?.data?.lane;
          if (lane) {
            this.getLaneObject(lane);
          }
          this.$bus.$emit("showLoader", false);
        })
        .catch(() => {
          this.$bus.$emit("showToastMessage", {
            message: `Couldn't find Customer Tariff with given id! ${
              this.$route && this.$route.params && this.$route.params.id
                ? this.$route.params.id
                : ""
            }`,
            color: "error",
          });
          this.$bus.$emit("showLoader", false);
        });
    },

    getLaneObject(id) {
      this.$api.lane
        .getLaneObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.lrfqDetails.lane = res.data;
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
        });
    },

    checkQuery() {
      if (this.$route.query) {
        if (this.$route.query.backRoute) {
          this.backRoute.path = this.$route.query.backRoute;
        }
        if (this.$route.query.active_tab) {
          if (!this.backRoute.query) {
            this.backRoute.query = {};
          }
          this.backRoute.query.active_tab = this.$route.query.active_tab;
        }
        if (this.$route.query.id) {
          this.getCustomerContractObject(this.$route.query.id);
        }
      }
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("contract");
      if (!permissions || !permissions.add) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.getContainersType();
        // this.getTransporterList();
        this.getLaneList();

        this.getCustomerContractOptions();
        this.checkQuery();
        this.getContainerCategoryList();
      }
    }
  },
};
</script>
