var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"45%","persistent":""},model:{value:(_vm.showSplitLegRateForm),callback:function ($$v) {_vm.showSplitLegRateForm=$$v},expression:"showSplitLegRateForm"}},[_c('v-card',[_c('v-card-title',{staticClass:"secondary white--text px-4 py-2"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(" Delivery Advice No. "+_vm._s(this.daDetails ? this.daDetails.da_no : "")+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.resetForm(), (_vm.showSplitLegRateForm = false)}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-close")])],1)],1),(_vm.type && _vm.type == 'Base')?_c('v-card-text',{staticClass:"pt-2"},[(_vm.showSplitLegRateForm)?_c('v-form',{ref:"chargeForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12","lg":"3"}},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(_vm.legNo))])]),_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12","lg":"9"}},[_c('InputField',{attrs:{"type":"number","label":`Leg Rate (${_vm.$globals.currency}) *`,"required":"","hide-details":"auto","readonly":"","disabled":"","error-messages":_vm.formErrors.rate},on:{"input":function($event){delete _vm.formErrors.rate}},model:{value:(_vm.splitRate),callback:function ($$v) {_vm.splitRate=$$v},expression:"splitRate"}})],1),_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12","lg":"3"}},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"18px"}},[_vm._v("Split DA Rate")])]),_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12","lg":"9"}},[_c('InputField',{attrs:{"type":"number","label":`Split Rate (${_vm.$globals.currency}) `,"required":"","rules":[
                (val) => !!val || 'Rate is required!',
                (val) => {
                  return (
                    Number(val) < Number(this.daDetails.rate) ||
                    'Split rate cannot be greater than the base rate!'
                  );
                },
              ],"hide-details":"auto","error-messages":_vm.formErrors.rate},on:{"input":function($event){delete _vm.formErrors.rate, _vm.updateTotalRate()}},model:{value:(_vm.splitLegRateDetails.rate),callback:function ($$v) {_vm.$set(_vm.splitLegRateDetails, "rate", $$v)},expression:"splitLegRateDetails.rate"}})],1)],1)],1):_vm._e()],1):_c('v-card-text',{staticClass:"pt-2"},[(_vm.showSplitLegRateForm)?_c('v-form',{ref:"chargeForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_vm._l((_vm.legDetails),function(item,i){return _c('v-row',{key:i,staticClass:"my-0"},[_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12","lg":"3"}},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(item.leg_id))])]),_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12","lg":"3"}},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(item.leg_lane_title))])]),_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12","lg":"6"}},[_c('InputField',{attrs:{"type":"number","label":`Leg Rate (${_vm.$globals.currency}) *`,"required":"","hide-details":"auto","rules":[
                (val) => !!val || 'Rate is required!',
                (val) => {
                  return (
                    _vm.totalRate() <= Number(_vm.daDetails.rate) ||
                    'Total rate cannot exceed the base rate!'
                  );
                },
              ],"error-messages":_vm.formErrors[`rate_${i}`]},on:{"input":function($event){_vm.updateTotalRate, delete _vm.formErrors[`rate_${i}`]}},model:{value:(item.rate),callback:function ($$v) {_vm.$set(item, "rate", $$v)},expression:"item.rate"}})],1)],1)}),_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12","lg":"3"}},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"18px"}},[_vm._v("Split DA Rate")])]),_c('v-col',{staticClass:"Form-Columns",attrs:{"cols":"12","lg":"9"}},[_c('InputField',{attrs:{"type":"number","label":`Split Rate (${_vm.$globals.currency}) *`,"required":"","rules":[
                (val) => !!val || 'Rate is required!',
                (val) => {
                  return (
                    Number(val) <= Number(this.daDetails.rate) ||
                    'Split rate cannot be greater than the base rate!'
                  );
                },
                (val) => {
                  return (
                    this.totalRate() <= Number(this.daDetails.rate) ||
                    'Total rate cannot exceed the base rate!'
                  );
                },
              ],"hide-details":"auto","error-messages":_vm.formErrors.splitRate},on:{"input":function($event){_vm.updateTotalRate, delete _vm.formErrors.splitRate}},model:{value:(_vm.splitLegRateDetails.rate),callback:function ($$v) {_vm.$set(_vm.splitLegRateDetails, "rate", $$v)},expression:"splitLegRateDetails.rate"}})],1)],1)],2):_vm._e()],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[(_vm.type && _vm.type == 'Base')?_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":_vm.isBaseAddButtonDisabled},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v(" Add ")]):_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":_vm.isAddButtonDisabled},on:{"click":function($event){return _vm.gatherSplitRateData()}}},[_vm._v(" Add ")]),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"secondary","depressed":""},on:{"click":_vm.resetForm}},[_vm._v(" Reset ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }