<template>
  <v-row class="pa-0">
    <v-col v-if="params.context.parentComponent.tab != 4">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            v-if="
              params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.add &&
              params.context.parentComponent.tab == 0
            "
            color="success"
            v-on="on"
            v-bind="attrs"
            @click="updateStatus(params.data, 'Accepted')"
            class="ml-1 mr-2"
          >
            mdi-check
          </v-icon>
        </template>
        <span>Accept</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2 mr-2"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="download()"
          >
            mdi-file-download
          </v-icon>
        </template>
        <span>Download Credit Note</span>
      </v-tooltip>

      <!-- <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2 mr-2"
            v-if="
              params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.add &&
              params.context.parentComponent.tab == 0
            "
            color="error"
            v-on="on"
            v-bind="attrs"
            @click="updateStatus(params.data, 'Rejected')"
          >
            mdi-close
          </v-icon>
        </template>
        <span>Reject</span>
      </v-tooltip> -->

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="editItem"
            class="ml-2 mr-2"
            v-if="params.data.full_invoice_credit_note == 'No'"
          >
            {{
              params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.add &&
              params.context.parentComponent.tab == 1
                ? "mdi-eye"
                : "mdi-pencil"
            }}
          </v-icon>
        </template>
        <span>
          {{
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.add &&
            params.context.parentComponent.tab == 1
              ? "View"
              : "Edit"
          }}</span
        >
      </v-tooltip>

      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2 mr-1"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="viewLogs"
          >
            mdi-information
          </v-icon>
        </template>
        <span>Credit Note Logs</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    viewItem() {
      this.params.context.parentComponent.viewCreditNote(this.params.data.id);
    },
    editItem() {
      this.params.context.parentComponent.editCreditNote(this.params.data);
    },

    download(daList = null) {
      this.params.context.parentComponent.downloadCreditNote(
        this.params.data,
        daList
      );
    },
    createCreditNote() {
      this.params.context.parentComponent.createCreditNote(this.params.data);
    },
    updateStatus(data, status) {
      this.params.context.parentComponent.openRemarksForm(data, status);
    },
    viewLogs() {
      this.params.context.parentComponent.viewCreditNOteLogs(
        this.params.data.id
      );
    },
  },
};
</script>

<style></style>
