<template>
  <ListComponent title="Invoice">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2 d-flex align-center">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchInvoice"
          ></v-text-field>

          <v-tooltip top>
            <span v-if="Object.keys(filters).length == 0">Filters</span>

            <v-row v-else no-gutters>
              <v-col cols="12" class="font-weight-bold">Applied Filters</v-col>
              <v-col cols="12">
                <span
                  v-for="(value, key) in filters"
                  :key="key"
                  class="text-capitalize"
                  >{{ key.replace("_", " ") }}
                  <br />
                </span>
              </v-col>
            </v-row>
          </v-tooltip>

          <Columns :headers="headers" :gridColInstance="gridColumnApi" />
        </v-col>

        <v-col cols="4" class="px-0 pt-0 pb-2">
          <v-tabs v-model="tab" height="35" centered>
            <v-tabs-slider></v-tabs-slider>
            <v-tab>
              Pending
              <v-badge
                v-if="totalInvoiceStatistics.pending_invoice"
                top
                :content="totalInvoiceStatistics.pending_invoice"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <v-tab>
              Accepted
              <v-badge
                v-if="totalInvoiceStatistics.accepted_invoice"
                top
                :content="totalInvoiceStatistics.accepted_invoice"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <v-tab>
              Rejected
              <v-badge
                v-if="totalInvoiceStatistics.rejected_invoice"
                top
                :content="totalInvoiceStatistics.rejected_invoice"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
          </v-tabs>
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="4"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            @columnMoved="resizeGrid"
            @columnVisible="resizeGrid"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allInvoiceList"
          ></AgGridVue>
        </v-col>
      </v-row>

      <v-dialog v-model="showDownloadPreview">
        <v-card>
          <v-card-title>
            <span>Download Preview</span>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2"
              color="primary"
              depressed
              @click="invoiceDownload"
              >Download Invoice</v-btn
            >
            <v-btn icon @click="closeDownloadPreview">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text v-if="downloadingInvoice">
            <InvoiceTemplate :invoiceDetails="downloadingInvoice" />
          </v-card-text>
        </v-card>
      </v-dialog>
      <RemarksDialog
        v-model="showRemarksForm"
        :formDetails="updatingInvoice"
        :formErrors="formErrors"
        fieldKey="customer_remarks"
        :label="updatingInvoice.status + ' Remarks *'"
        @submitRemarks="updateInvoiceStatus"
      />
      <CustomerCustomerInvoiceFilters
        :bindingObject="filters"
        v-model="showFiltersDialog"
        @resetFilters="resetFilters"
        @applyFilters="itemsPerPageChanged(20)"
      />
    </template>
  </ListComponent>
</template>

<script>
import Columns from "@/components/General/Columns.vue";
import ListComponent from "@/components/General/ListComponent.vue";
import RemarksDialog from "@/components/ModuleBased/dialogs/RemarksDialog.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import InvoiceTemplate from "@/components/General/InvoiceTemplate.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";
import CustomerInvoiceButton from "@/components/AgGridComponents/CustomerInvoiceButton.vue";
import CustomerCustomerInvoiceFilters from "@/components/ModuleBased/dialogs/filters/CustomerCustomerInvoiceFilters.vue";

export default {
  components: {
    ListComponent,
    Columns,
    AgGridVue,
    Pagination,
    InvoiceTemplate,
    CustomerInvoiceButton,
    agColumnHeader: CustomHeader,
    RemarksDialog,
    CustomerCustomerInvoiceFilters,
  },
  watch: {
    tab() {
      this.pageNo = 1;
      this.getCustomerInvoiceList();
    },
  },
  data() {
    return {
      sorting: {},
      headers: [
        {
          headerName: "Invoice No",
          field: "invoice_no",
          sortable: true,
        },
        {
          headerName: "Invoice Date",
          field: "invoice_date",
          sortable: true,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        { headerName: "BOE", field: "boe", sortable: true },
        {
          headerName: `Total Amount (${this.$globals.currency})`,
          field: "total_amount",
          sortable: true,
        },
        {
          headerName: "Total Gross amount",
          field: "total_gross_amount",
          sortable: true,
        },
        { headerName: "Invoice Type", field: "invoice_type" },
        { headerName: "Job Type", field: "job_type" },
        {
          headerName: "Actions",
          field: "actions",
          width: 200,
          pinned: "right",
          cellRendererFramework: "CustomerInvoiceButton",
        },
      ],

      permissions: null,
      da_permissions: null,

      // details vars
      selectedInvoiceId: null,
      showDetails: false,

      // Pagination Vars
      tab: null,
      itemsPerPage: 20,
      pageNo: 1,
      totalItems: 0,
      filters: {},
      showFiltersDialog: false,

      // Ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        width: 150,
        resizable: true,
        flex: 1,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },

      allInvoiceList: [],
      totalInvoiceStatistics: [],

      // update Status vars
      showRemarksForm: false,
      updatingInvoice: {},
      formErrors: {},

      // download vars
      showDownloadPreview: false,
      downloadingInvoice: null,
      invoiceId: null,
      searchInvoiceStatisticTimeout: null,
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
  },

  methods: {
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(20);
    },
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    closeDownloadPreview() {
      this.downloadingInvoice = null;
      this.showDownloadPreview = false;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    openRemarksForm(contract, status) {
      this.updatingInvoice = {
        id: contract.id,
        status: status,
        customer_remarks: null,
      };
      this.showRemarksForm = true;
    },
    searchInvoice() {
      this.pageNo = 1;
      this.getCustomerInvoiceList();
    },

    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getCustomerInvoiceList();
    },

    prevPage() {
      this.pageNo--;
      this.getCustomerInvoiceList();
    },

    nextPage() {
      this.pageNo++;
      this.getCustomerInvoiceList();
    },

    viewInvoice(id) {
      let path = {
        path: `/app/customer/invoice/details/${id}`,
        query: {
          active_tab: this.tab,
        },
      };
      this.$router.push(path);
    },

    checkQuery() {
      if ("active_tab" in this.$route.query) {
        this.tab = parseInt(this.$route.query.active_tab);
        // this.getTotalInvoiceCount();
      }
    },

    getCustomerInvoiceList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
        customer: this.currentProfile.company,
        status:
          this.tab == 0 ? "Pending" : this.tab == 1 ? "Accepted" : "Rejected",
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.customerInvoice
        .getCustomerInvoiceList(params)
        .then((res) => {
          this.allInvoiceList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    async downloadInvoice(id) {
      this.invoiceId = id;
      await this.$api.customerInvoice
        .getCustomerInvoiceObject(id)
        .then(async (res) => {
          this.downloadingInvoice = res.data;
          this.showDownloadPreview = true;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async invoiceDownload() {
      this.$bus.$emit("showLoader", true);
      await this.$api.customerInvoice
        .getCustomerInvoiceBlopObject(this.invoiceId)
        .then(async (response) => {
          this.$bus.$emit("showLoader", false);
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.downloadingInvoice.invoice_no + ".pdf";
          link.click();
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    downloadGeneratedInvoice() {
      let opt = {
        margin: 0.25,
        filename: `${this.downloadingInvoice.invoice_no}.pdf`,
        pagebreak: { mode: ["legacy"] },
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 1,
          dpi: 300,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      };
      let element = document.getElementById("invoiceAttachment");
      this.$html2pdf().set(opt).from(element).save();
    },
    updateInvoiceStatus() {
      this.$api.customerInvoice
        .partiallyUpdateCustomerInvoice(
          this.updatingInvoice.id,
          this.updatingInvoice
        )
        .then((res) => {
          this.getCustomerInvoiceList();
          this.$bus.$emit("showToastMessage", {
            message: "Invoice status updated successfully!",
            color: "success",
          });
          this.showRemarksForm = false;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't update invoice status!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("order");
      this.da_permissions = getPermissions("delivery_advice");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
      }
    }
  },

  mounted() {
    this.checkQuery();
    // this.getTotalInvoiceCount();
  },
};
</script>
