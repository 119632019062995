<template>
  <v-dialog fullscreen v-model="showSubhireDetailDialog">
    <v-card>
      <v-card-title class="secondary white--text px-4 py-2">
        <div>
          <span> Sub Hire {{ subHireData.sub_hire_id }} - Detail View</span>
        </div>

        <v-spacer></v-spacer>
        <v-btn icon @click="showSubhireDetailDialog = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-2">
        <v-row class="ma-0">
          <v-col cols="12" lg="7">
            <v-row>
              <v-col cols="12" lg="auto">
                <span class="text-caption">Route</span>

                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ daDetails.leg_lane_title }}
                </p>
              </v-col>
              <v-divider vertical class="mx-3"></v-divider>

              <v-col cols="12" lg="auto">
                <span class="text-caption">Customer</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireData.customer }}
                </p>
              </v-col>
              <v-divider vertical class="mx-3"></v-divider>

              <v-col cols="12" lg="auto">
                <span class="text-caption">Revenue</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ revenue }} {{ $globals.currency }}
                </p>
              </v-col>
              <v-divider vertical class="mx-3"></v-divider>

              <v-col cols="12" lg="auto">
                <span class="text-caption">Additional Charge</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ additionalCharge }}
                  {{ $globals.currency }}
                </p>
              </v-col>
              <v-divider vertical class="mx-3"></v-divider>

              <v-col cols="12" lg="auto">
                <span class="text-caption">Total Revenue</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ totalRevenue }} {{ $globals.currency }}
                </p>
              </v-col>
              <v-divider vertical class="mx-3"></v-divider>

              <v-col cols="12" lg="auto">
                <span class="text-caption">Taxable Amount</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ daDetails.subhire_das_taxable_amount }}
                  {{ $globals.currency }}
                </p>
              </v-col>
              <v-divider vertical class="mx-3"></v-divider>

              <v-col cols="12" lg="auto">
                <span class="text-caption">Total Revenue With Tax</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ grandTotalProperty }} {{ $globals.currency }}
                </p>
              </v-col>
              <v-divider vertical class="mx-3"></v-divider>

              <v-col cols="12" lg="auto">
                <span class="text-caption">Sub Hire Leg Count</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireData.sub_hire_leg_count }}
                </p>
              </v-col>

              <v-divider vertical class="mx-3"></v-divider>

              <v-col cols="12" lg="auto">
                <span class="text-caption">Added By</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireData.added_by }}
                </p>
              </v-col>

              <v-divider
                v-if="subHireData.approved_by"
                vertical
                class="mx-3"
              ></v-divider>

              <v-col cols="12" lg="auto" v-if="subHireData.approved_by">
                <span class="text-caption">Approved By</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireData.approved_by }}
                </p>
              </v-col>

              <v-divider
                vertical
                class="mx-3"
                v-if="subHireData.invoice_no"
              ></v-divider>

              <v-col cols="12" lg="auto" v-if="subHireData.invoice_no">
                <span class="text-caption">Invoice No</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireData.invoice_no }}
                </p>
              </v-col>

              <v-divider
                vertical
                class="mx-3"
                v-if="subHireData.invoice_date"
              ></v-divider>

              <v-col cols="12" lg="auto" v-if="subHireData.invoice_date">
                <span class="text-caption">Invoice Date</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireData.invoice_date | formatDate }}
                </p>
              </v-col>

              <v-divider
                vertical
                class="mx-3"
                v-if="subHireData.invoice_by"
              ></v-divider>

              <v-col cols="12" lg="auto" v-if="subHireData.invoice_by">
                <span class="text-caption">Invoice Detail Updated By</span>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireData.invoice_by }}
                </p>
              </v-col>

              <v-divider vertical class="mx-3"></v-divider>

              <v-col cols="12" lg="auto" v-if="subHireData.completed_by">
                <span class="text-caption" v-if="subHireData.completed_by"
                  >Completed By</span
                >
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  {{ subHireData.completed_by }}
                </p>
              </v-col>

              <v-col cols="12" class="px-0">
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12" lg="3" class="Form-Columns-Dense">
                <InputField
                  type="text"
                  label="Select Transporter *"
                  hide-details="auto"
                  :clearable="false"
                  v-model="subHireData.transporter"
                  readonly
                  class="grey lighten-3"
                />
              </v-col>

              <v-col cols="12" lg="3" class="Form-Columns-Dense">
                <InputField
                  type="number"
                  :label="`Transport Charge (${$globals.currency}) *`"
                  hide-details="auto"
                  :clearable="false"
                  v-model="subHireData.rate"
                  readonly
                  class="grey lighten-3"
                />
              </v-col>
              <v-col cols="12" lg="3" class="Form-Columns-Dense">
                <InputField
                  type="number"
                  :clearable="false"
                  readonly
                  label="Tax(%) *"
                  class="grey lighten-3"
                  hide-details="auto"
                  v-model="subHireData.tax_percentage"
                />
              </v-col>
              <v-col cols="12" lg="3" class="Form-Columns-Dense">
                <InputField
                  type="number"
                  readonly
                  :label="`Additional Charge (${$globals.currency})`"
                  class="grey lighten-3"
                  hide-details="auto"
                  :clearable="false"
                  v-model="subHireData.sub_hire_additional_amount"
                />
              </v-col>
              <v-col cols="12" lg="3" class="Form-Columns-Dense">
                <InputField
                  type="number"
                  readonly
                  :label="`Total Cost (${$globals.currency})`"
                  class="grey lighten-3"
                  hide-details="auto"
                  :clearable="false"
                  v-model="subHireData.total_cost"
                />
              </v-col>

              <v-col cols="12" lg="3" class="Form-Columns-Dense">
                <InputField
                  type="number"
                  :label="`Total Taxable Amount (${$globals.currency})`"
                  class="grey lighten-3"
                  :clearable="false"
                  readonly
                  hide-details="auto"
                  v-model="subHireData.total_taxable_amount"
                />
              </v-col>
              <v-col cols="12" lg="3" class="Form-Columns-Dense">
                <InputField
                  type="number"
                  :label="`Total Cost With Tax (${$globals.currency})`"
                  :clearable="false"
                  class="grey lighten-3"
                  readonly
                  hide-details="auto"
                  v-model="subHireData.total_cost_with_tax"
                />
              </v-col>
              <v-col cols="12" lg="3" class="Form-Columns-Dense">
                <InputField
                  type="number"
                  min="0"
                  readonly
                  :label="`Margin Rate (${$globals.currency})`"
                  class="grey lighten-3"
                  hide-details="auto"
                  v-model="marginRate"
                  :clearable="false"
                />
              </v-col>
              <v-col cols="12" lg="3" class="Form-Columns-Dense">
                <InputField
                  type="number"
                  min="0"
                  readonly
                  :label="`Margin Percentage*`"
                  class="grey lighten-3"
                  :clearable="false"
                  hide-details="auto"
                  v-model="marginPercent"
                />
              </v-col>

              <v-col cols="12" lg="3" class="Form-Columns-Dense">
                <InputField
                  type="text"
                  label="Reason For Sub Hire*"
                  readonly
                  class="grey lighten-3"
                  hide-details="auto"
                  v-model="subHireData.reason_for_sub_hire"
                  :clearable="false"
                />
              </v-col>
              <v-col cols="12" lg="3" class="Form-Columns-Dense">
                <v-switch
                  class="mt-1"
                  hide-details="auto"
                  label="Cargo liability insurance"
                  readonly
                  true-value="Yes"
                  false-value="No"
                  v-model="subHireData.cargo_liability_insurance"
                ></v-switch>
              </v-col>
              <v-col cols="12" lg="3" class="Form-Columns-Dense">
                <v-switch
                  class="mt-1"
                  hide-details="auto"
                  label="General third party insurance"
                  readonly
                  true-value="Yes"
                  false-value="No"
                  v-model="subHireData.general_third_party_insurance"
                ></v-switch>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" lg="5">
            <AgGridVue
              style="width: 100%; height: 30vh"
              class="ag-theme-alpine"
              id="combineLegGrid"
              :columnDefs="headers"
              :context="context"
              @grid-ready="onGridReady"
              :defaultColDef="defaultColDef"
              :grid-options="gridOptions"
              :rowData="allRowList"
            ></AgGridVue>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="6" class="Form-Columns-Dense">
            <span class="text-h6 font-weight-regular"> Sub Hire Charges </span>
          </v-col>

          <v-col cols="12" class="Form-Columns-Dense px-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="12" class="Form-Columns-Dense">
            <AgGridVue
              style="width: 100%; height: 300px"
              class="ag-theme-alpine"
              id="myChargeGrid"
              :columnDefs="chargeHeaders"
              :grid-options="chargeGridOptions"
              :suppressDragLeaveHidesColumns="true"
              :rowData="allChargeList"
            ></AgGridVue>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="pa-2"> </v-card-text>

      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import { AgGridVue } from "ag-grid-vue";

export default {
  components: {
    InputField,
    MultiSelect,
    AgGridVue,
    FormComponent,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    subHireID: {
      default: null,
    },
  },

  data() {
    return {
      grandTotal: 0,

      subHireNo: null,

      //subhire detail view variables
      subHireData: [],
      allRowList: [],
      allChargeList: [],
      daDetails: {},

      //transport rate calculation
      transportTotalAdditionalCharge: 0,

      marginRate: 0,
      marginPercent: 0,

      headers: [
        {
          headerName: "Leg",
          field: "leg_id",
          minWidth: 150,
        },
        {
          headerName: "Rate(AED)",
          field: "leg_rate",
          minWidth: 150,
        },
        {
          headerName: "DA",
          field: "da_no",
          minWidth: 120,
        },
        {
          headerName: "Status",
          field: "status",
          minWidth: 100,
        },
        {
          headerName: "BOE",
          field: "boe",
          minWidth: 120,
        },
      ],
      // charge ag-grid vars
      chargeHeaders: [
        {
          headerName: "Charge Title",
          field: "charge_title",
          minWidth: 300,
        },
        {
          headerName: "Cost GL No.",
          field: "cost_gl_no",
          minWidth: 150,
        },
        {
          headerName: "TAX(%)",
          field: "tax_percentage",
          minWidth: 100,
        },
        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "rate",
          minWidth: 100,
        },
      ],
      chargeGridApi: null,
      chargeGridColumnApi: null,

      chargeGridOptions: {
        defaultColDef: {
          resizable: true,
          flex: 1,
        },

        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },
      legObject: {},

      defaultColDef: {
        resizable: true,
        flex: 1,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },
  watch: {
    value(val) {
      if (val) {
        if (this.subHireID) {
          this.getSubHireDetail(this.subHireID);
          this.subHireNo = this.subHireNumber;
        }
      }
    },
  },

  computed: {
    showSubhireDetailDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    context() {
      return { parentComponent: this };
    },
    revenue() {
      return (
        parseFloat(this.subHireData.da_rate) *
        this.subHireData.sub_hire_leg_count
      ).toFixed(2);
    },
    additionalCharge() {
      return parseFloat(
        this.daDetails.all_subhire_das_additional_amount
      ).toFixed(2);
    },
    totalRevenue() {
      return (
        parseFloat(this.revenue) + parseFloat(this.additionalCharge)
      ).toFixed(2);
    },
    grandTotalProperty() {
      return (
        parseFloat(this.totalRevenue) +
        parseFloat(this.daDetails.subhire_das_taxable_amount)
      ).toFixed(2);
    },
  },

  methods: {
    getSubHireDetail() {
      this.$bus.$emit("showLoader", true);
      this.$api.subHire
        .getSubHireDetails(this.subHireID)
        .then((res) => {
          this.subHireData = res.data;
          this.allRowList = res.data.sub_hire_leg;
          this.allChargeList = res.data.sub_hire_job_charges;
          this.daDetails = res.data.da_charges_details;
          this.marginRate = (
            parseFloat(this.totalRevenue) -
            parseFloat(this.subHireData.total_cost)
          ).toFixed(2);
          this.marginPercent = (
            (this.marginRate / parseFloat(this.totalRevenue)) *
            100
          ).toFixed(2);

          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
  },
};
</script>
