<template>
  <FormComponent
    title="Edit Transporter"
    backRoute="/app/admin/company-management/transporter/list"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #formActions>
      <v-btn
        color="primary"
        depressed
        :disabled="!allFormValidation"
        @click="submitForm"
      >
        Update
      </v-btn>
    </template>

    <template #formContent>
      <v-stepper v-model="stepper" non-linear flat>
        <v-stepper-header class="elevation-0">
          <v-stepper-step
            class="pa-2"
            :rules="[() => (Object.keys(formErrors).length ? valid1 : true)]"
            step="1"
            editable
          >
            Company Details
          </v-stepper-step>
          <v-divider class="ma-0"></v-divider>
          <v-stepper-step
            :rules="[
              () =>
                formErrors.company_contacts &&
                formErrors.company_contacts.length
                  ? valid2
                  : true,
            ]"
            step="2"
            editable
          >
            Contact Details
          </v-stepper-step>
          <v-divider class="ma-0"></v-divider>
          <v-stepper-step
            :rules="[() => (Object.keys(formErrors).length ? valid3 : true)]"
            step="3"
            editable
          >
            Document Details
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1" class="pa-0">
            <v-form ref="stepperForm1" v-model="valid1">
              <v-row class="ma-0">
                <v-col cols="12" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">Basic Details</span>
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    hide-details="auto"
                    type="text"
                    v-model="companyDetails.company_code"
                    :rules="[(val) => !!val || 'Company Code is Required!']"
                    label="Company Code *"
                    required
                    :error-messages="formErrors.company_code"
                    @input="delete formErrors.company_code"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    hide-details="auto"
                    type="text"
                    v-model="companyDetails.company_name"
                    :rules="[(val) => !!val || 'Company Name is Required!']"
                    label="Company Name *"
                    required
                    :error-messages="formErrors.company_name"
                    @input="delete formErrors.company_name"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <v-switch
                    hide-details="auto"
                    v-model="companyDetails.is_internal_company"
                    :label="`Internal Company : ${
                      companyDetails.is_internal_company == 'Yes' ? 'Yes' : 'No'
                    }`"
                    true-value="Yes"
                    false-value="No"
                    class="mt-1"
                    :error-messages="formErrors.is_internal_company"
                    @change="delete formErrors.is_internal_company"
                  ></v-switch>
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <div class="d-flex align-baseline">
                    <span class="Word-No-Break text-subtitle-1">
                      Rating : {{ companyDetails.rating }}
                    </span>

                    <v-rating
                      hide-details="auto"
                      v-model="companyDetails.rating"
                      background-color="grey"
                      color="primary"
                      half-increments
                      hover
                      size="30"
                    ></v-rating>
                  </div>
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">
                    Address Details
                  </span>
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    hide-details="auto"
                    v-model="companyDetails.address_line_1"
                    :rules="[(val) => !!val || 'Address line 1 is Required!']"
                    label="Address Line 1 *"
                    required
                    :error-messages="formErrors.address_line_1"
                    @input="delete formErrors.address_line_1"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    hide-details="auto"
                    v-model="companyDetails.address_line_2"
                    label="Address Line 2"
                    :error-messages="formErrors.address_line_2"
                    @input="delete formErrors.address_line_2"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    hide-details="auto"
                    :items="countryList"
                    v-model="companyDetails.country"
                    :rules="[(val) => !!val || 'Country is Required!']"
                    label="Country *"
                    itemText="name"
                    itemValue="id"
                    required
                    :returnObject="true"
                    :error-messages="formErrors.country"
                    @change="
                      delete formErrors.country;
                      checkData($event, 'country');
                    "
                  ></MultiSelect>
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    hide-details="auto"
                    :items="cityList"
                    v-model="companyDetails.city"
                    :rules="[(val) => !!val || 'City is Required!']"
                    itemText="city_name"
                    itemValue="id"
                    label="City *"
                    required
                    :error-messages="formErrors.city"
                    @change="delete formErrors.city"
                  ></MultiSelect>
                </v-col>

                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="12" class="Form-Columns-Dense">
                      <span class="text-h6 font-weight-regular">
                        Contact Details
                      </span>
                    </v-col>

                    <v-col cols="12" lg="6">
                      <v-row>
                        <v-col cols="12" lg="5" class="Form-Columns-Dense">
                          <InputField
                            hide-details="auto"
                            type="number"
                            v-model="companyDetails.phone_code"
                            :rules="[
                              (val) => !!val || 'Phone Code is Required!',
                            ]"
                            readonly
                            :clearable="false"
                            label="Phone Code *"
                            required
                            :error-messages="formErrors.phone_code"
                            @input="delete formErrors.phone_code"
                          />
                        </v-col>
                        <v-col cols="12" lg="7" class="Form-Columns-Dense">
                          <InputField
                            hide-details="auto"
                            type="number"
                            v-model="companyDetails.company_contact_number"
                            :rules="[
                              (val) =>
                                !!val || 'Company Contact Number is Required!',
                            ]"
                            label="Contact Number *"
                            required
                            :error-messages="formErrors.company_contact_number"
                            @input="delete formErrors.company_contact_number"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <InputField
                        hide-details="auto"
                        type="text"
                        v-model="companyDetails.company_email"
                        :rules="[
                          (val) => !!val || 'Company Email is Required!',
                          (val) =>
                            /.+@.+\..+/.test(val) || 'Email must be valid',
                        ]"
                        label="Company Email *"
                        required
                        :error-messages="formErrors.company_email"
                        @input="delete formErrors.company_email"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="12" class="Form-Columns-Dense">
                      <span class="text-h6 font-weight-regular">
                        Payment Details
                      </span>
                    </v-col>
                    <v-col cols="12" lg="3" class="Form-Columns-Dense">
                      <MultiSelect
                        hide-details="auto"
                        :items="paymentTerms"
                        v-model="companyDetails.payment_term"
                        :rules="[
                          (val) => !!val || 'Payment Terms is Required!',
                        ]"
                        label="Payment Terms *"
                        required
                        :error-messages="formErrors.payment_term"
                        @change="delete formErrors.payment_term"
                      ></MultiSelect>
                    </v-col>
                    <v-col cols="12" lg="3" class="Form-Columns-Dense">
                      <MultiSelect
                        hide-details="auto"
                        :items="currencyList"
                        v-model="companyDetails.currency"
                        :rules="[(val) => !!val || 'Currency is Required!']"
                        itemText="currency_code"
                        itemValue="currency_code"
                        label="Currency *"
                        required
                        :error-messages="formErrors.currency"
                        @change="delete formErrors.currency"
                      ></MultiSelect>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="12" class="Form-Columns-Dense">
                      <span class="text-h6 font-weight-regular">
                        VAT Registration Details
                      </span>
                    </v-col>
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <v-switch
                        hide-details="auto"
                        v-model="companyDetails.vat_applicability"
                        :label="`VAT Applicability : ${
                          companyDetails.vat_applicability == 'Yes'
                            ? 'Yes'
                            : 'No'
                        }`"
                        true-value="Yes"
                        false-value="No"
                        class="mt-1"
                        :error-messages="formErrors.vat_applicability"
                        @change="delete formErrors.vat_applicability"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <InputField
                        hide-details="auto"
                        type="text"
                        v-model="companyDetails.vat_registration_number"
                        :rules="[
                          ...(companyDetails.vat_applicability == 'Yes'
                            ? [
                                (val) =>
                                  !!val || 'VAT Registration No is Required!',
                              ]
                            : []),
                        ]"
                        :label="`VAT Registration No ${
                          companyDetails.vat_applicability == 'Yes' ? '*' : ''
                        }`"
                        required
                        :error-messages="formErrors.vat_registration_number"
                        @input="delete formErrors.vat_registration_number"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="12" class="Form-Columns-Dense">
                      <span class="text-h6 font-weight-regular">
                        Service Type
                      </span>
                    </v-col>

                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <v-switch
                        hide-details="auto"
                        v-model="companyDetails.is_local"
                        :label="`Local : ${
                          companyDetails.is_local == 'Yes' ? 'Yes' : 'No'
                        }`"
                        true-value="Yes"
                        false-value="No"
                        class="mt-1"
                        :error-messages="formErrors.is_local"
                        @change="delete formErrors.is_local"
                      ></v-switch>
                    </v-col>

                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <v-switch
                        hide-details="auto"
                        v-model="companyDetails.is_cross_border"
                        :label="`Cross Border : ${
                          companyDetails.is_cross_border == 'Yes' ? 'Yes' : 'No'
                        }`"
                        true-value="Yes"
                        false-value="No"
                        class="mt-1"
                        :error-messages="formErrors.is_cross_border"
                        @change="delete formErrors.is_cross_border"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">
                    Other Details
                  </span>
                </v-col>

                <v-col cols="12" lg="6" class="Form-Columns-Dense">
                  <TextareaField
                    hide-details="auto"
                    v-model="companyDetails.remarks"
                    :rules="[
                      (value) =>
                        (value || '').length <= 255 || 'Max 255 Characters',
                    ]"
                    label="Remarks"
                    counter
                    maxlength="255"
                    auto-grow
                    rows="2"
                    row-height="2"
                    :error-messages="formErrors.remarks"
                    @input="delete formErrors.remarks"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    hide-details="auto"
                    :items="allContainerCategoryList"
                    itemText="name"
                    itemValue="id"
                    v-model="companyDetails.container_category"
                    :rules="[
                      (val) => !!val || 'Container Category is Required!',
                    ]"
                    label="Select Container Category *"
                    required
                    :error-messages="formErrors.container_category"
                    @input="delete formErrors.container_category"
                  ></MultiSelect>
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense mt-6">
                  <v-btn
                    depressed
                    color="primary"
                    @click="stepper = 2"
                    :disabled="!valid1"
                  >
                    Next
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2" class="pa-0">
            <v-form ref="stepperForm2" v-model="valid2">
              <v-row
                class="mx-2 my-0"
                v-if="contactArray && contactArray.length == 0"
              >
                <v-col cols="12" lg="6" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">
                    Add Contact Person
                  </span>
                </v-col>

                <v-col cols="12" lg="6" class="Form-Columns-Dense text-right">
                  <v-btn
                    depressed
                    small
                    color="primary"
                    @click="contactArray.push({})"
                    :disabled="contactArray.length >= 5"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense">
                  <v-divider></v-divider>
                </v-col>
              </v-row>

              <v-row
                class="mx-2 my-0"
                v-for="(contact, i) in contactArray"
                :key="i"
              >
                <v-col cols="12" class="d-flex Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">
                    Contact Person #{{ i + 1 }}
                  </span>

                  <v-spacer></v-spacer>

                  <v-btn
                    depressed
                    small
                    class="mr-2"
                    color="primary"
                    @click="contactArray.push({})"
                    :disabled="contactArray.length >= 5"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>

                  <v-btn
                    depressed
                    small
                    color="secondary"
                    @click="contactArray.splice(i, 1)"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense">
                  <v-divider></v-divider>
                </v-col>

                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    hide-details="auto"
                    type="text"
                    v-model="contact.contact_person"
                    label="Contact Person *"
                    :rules="[(val) => !!val || 'Contact Person is Required!']"
                    required
                    :error-messages="
                      formErrors.company_contacts &&
                      formErrors.company_contacts[i] &&
                      formErrors.company_contacts[i].contact_person
                        ? formErrors.company_contacts[i].contact_person
                        : null
                    "
                    @input="
                      formErrors.company_contacts &&
                      formErrors.company_contacts[i]
                        ? (delete formErrors.company_contacts[i].contact_person,
                          checkContactFormErrors())
                        : null
                    "
                  />
                </v-col>
                <v-col cols="12" lg="3">
                  <v-row>
                    <v-col cols="12" lg="5" class="Form-Columns-Dense">
                      <MultiSelect
                        hide-details="auto"
                        :items="allPhoneCodeList"
                        label="Phone Code *"
                        :rules="[(val) => !!val || 'Phone Code is Required!']"
                        v-model="contact.phone_code"
                        :error-messages="
                          formErrors.company_contacts &&
                          formErrors.company_contacts[i] &&
                          formErrors.company_contacts[i].phone_code
                            ? formErrors.company_contacts[i].phone_code
                            : null
                        "
                        @change="
                          formErrors.company_contacts &&
                          formErrors.company_contacts[i]
                            ? (delete formErrors.company_contacts[i].phone_code,
                              checkContactFormErrors())
                            : null
                        "
                      />
                    </v-col>
                    <v-col cols="12" lg="7" class="Form-Columns-Dense">
                      <InputField
                        hide-details="auto"
                        type="number"
                        v-model="contact.contact_number"
                        label="Contact Number *"
                        :rules="[
                          (val) => !!val || 'Contact Number is Required!',
                        ]"
                        required
                        :error-messages="
                          formErrors.company_contacts &&
                          formErrors.company_contacts[i] &&
                          formErrors.company_contacts[i].contact_number
                            ? formErrors.company_contacts[i].contact_number
                            : []
                        "
                        @input="
                          formErrors.company_contacts &&
                          formErrors.company_contacts[i]
                            ? (delete formErrors.company_contacts[i]
                                .contact_number,
                              checkContactFormErrors())
                            : null
                        "
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    hide-details="auto"
                    type="text"
                    v-model="contact.contact_email"
                    label="Contact Email *"
                    :rules="[(val) => !!val || 'Contact Email is Required!']"
                    required
                    :error-messages="
                      formErrors.company_contacts &&
                      formErrors.company_contacts[i] &&
                      formErrors.company_contacts[i].contact_email
                        ? formErrors.company_contacts[i].contact_email
                        : null
                    "
                    @input="
                      formErrors.company_contacts &&
                      formErrors.company_contacts[i]
                        ? (delete formErrors.company_contacts[i].contact_email,
                          checkContactFormErrors())
                        : null
                    "
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    hide-details="auto"
                    :items="allDepartmentsList"
                    label="Department *"
                    itemText="department_name"
                    itemValue="id"
                    :rules="[(val) => !!val || 'Department is Required!']"
                    v-model="contact.department"
                    :error-messages="
                      formErrors.company_contacts &&
                      formErrors.company_contacts[i] &&
                      formErrors.company_contacts[i].department
                        ? formErrors.company_contacts[i].department
                        : null
                    "
                    @input="
                      formErrors.company_contacts &&
                      formErrors.company_contacts[i]
                        ? (delete formErrors.company_contacts[i].department,
                          checkContactFormErrors())
                        : null
                    "
                  />
                </v-col>
              </v-row>
              <v-row class="mx-2 my-0">
                <v-col cols="12" class="Form-Columns-Dense mt-6">
                  <v-btn
                    class="mr-2"
                    depressed
                    color="secondary"
                    @click="stepper = 1"
                  >
                    Previous
                  </v-btn>

                  <v-btn
                    depressed
                    color="primary"
                    @click="stepper = 3"
                    :disabled="!valid2"
                  >
                    Next
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="3" class="pa-0">
            <v-form ref="stepperForm3" v-model="valid3">
              <v-row class="mx-2 my-0">
                <v-col cols="12" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">
                    Document Details
                  </span>
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    hide-details="auto"
                    :items="CompanyDocumentType"
                    v-model="uploadDocumentType"
                    label="Document Type"
                    :error-messages="formErrors.uploadDocumentType"
                    @change="delete formErrors.uploadDocumentType"
                  ></MultiSelect>
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <DateField
                    :inputProps="{
                      dense: true,
                      label: 'Expiry Date',
                      clearable: true,
                      'hide-details': 'auto',
                      'error-messages': formErrors.uploadExpiryDate,
                    }"
                    v-model="uploadExpiryDate"
                    @input="delete formErrors.uploadExpiryDate"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    hide-details="auto"
                    type="text"
                    label="Description"
                    v-model="uploadDescription"
                    :error-messages="formErrors.uploadDescription"
                    @change="delete formErrors.uploadDescription"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <v-file-input
                    hide-details="auto"
                    :label="
                      uploadDocumentType
                        ? 'Upload Document *'
                        : 'Upload Document'
                    "
                    :rules="[
                      (file) =>
                        !file ||
                        file.size < 2e6 ||
                        'File size should be less than 2 MB!',
                    ]"
                    accept="image/x-png,image/jpg,image/jpeg,application/pdf"
                    v-model="uploadFiles"
                    :validation="uploadDocumentType ? 'required' : ''"
                    @change="getFileData($event)"
                    show-size
                    outlined
                    dense
                  ></v-file-input>
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense mt-6">
                  <v-btn depressed color="secondary" @click="stepper = 2">
                    Previous
                  </v-btn>
                </v-col>

                <v-col
                  v-show="
                    companyDetails.company_documents &&
                    companyDetails.company_documents.length
                  "
                  cols="12"
                  class="Form-Columns-Dense"
                >
                  <span class="text-h6 font-weight-regular"> Uploaded </span>
                  <v-card elevation="0" outlined>
                    <v-card-text class="py-1 px-0">
                      <v-row
                        class="ma-0"
                        v-for="(data, i) in companyDetails.company_documents"
                        :key="i"
                      >
                        <v-col cols="12" lg="2" class="py-1">
                          <a :href="data.document" target="_black">
                            <span>{{ data.document_type }}</span>
                          </a>
                        </v-col>

                        <v-col cols="12" lg="3" class="py-1">
                          Expires on : {{ data.document_exp_date | formatDate }}
                        </v-col>

                        <v-col cols="12" lg="3" class="py-1">
                          Created on : {{ data.created | formatDate(true) }}
                        </v-col>

                        <v-col cols="12" lg="3" class="py-1">
                          Added By : {{ data.added_by }}
                        </v-col>

                        <v-col cols="12" lg="1" class="text-right py-1">
                          <v-icon
                            small
                            color="red"
                            @click="deleteDocument(data, i)"
                          >
                            mdi-close
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col
                  v-show="documentList && documentList.length"
                  cols="12"
                  class="Form-Columns-Dense"
                >
                  <span class="text-h6 font-weight-regular"> New Upload </span>
                  <v-card elevation="0" outlined>
                    <v-card-text class="py-1 px-0">
                      <v-row
                        class="ma-0"
                        v-for="(data, i) in documentList"
                        :key="i"
                      >
                        <v-col cols="12" lg="9" class="py-1">
                          <a :href="generateUrl(data.file)" target="_black">
                            <span>{{ data.file.name }}</span>
                          </a>
                        </v-col>

                        <v-col cols="6" lg="2" class="py-1">
                          <span>{{ getSize(data.file.size) }}</span>
                        </v-col>

                        <v-col cols="6" lg="1" class="text-right py-1">
                          <v-icon small color="primary" @click="removeFile(i)">
                            mdi-close
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </FormComponent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import TextareaField from "@/components/FormBaseComponents/TextareaField.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import FormComponent from "@/components/General/FormComponent.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    InputField,
    MultiSelect,
    TextareaField,
    DateField,
    FormComponent,
  },
  data() {
    return {
      stepper: "1",
      valid1: true,
      valid2: true,
      valid3: true,

      documentList: [],
      CompanyDocumentType: [],
      allContainerCategoryList: [],
      uploadFiles: null,
      uploadDocumentType: null,
      uploadDescription: null,
      uploadExpiryDate: null,

      countryList: [],
      cityList: [],
      currencyList: [],

      contactArray: [],

      paymentTerms: [],
      formErrors: {},
      companyDetails: {
        vat_applicability: "No",
        is_local: "No",
        is_cross_border: "No",
      },
      serviceTypeList: [],
      allDepartmentsList: [],
      allPhoneCodeList: [],
    };
  },

  computed: {
    allFormValidation() {
      return this.valid1 && this.valid2 && this.valid3;
    },
  },

  methods: {
    checkContactFormErrors() {
      if (
        this.formErrors["company_contacts"] &&
        this.formErrors["company_contacts"].length != 0 &&
        this.formErrors["company_contacts"].every(
          (errObj) => Object.keys(errObj).length == 0
        )
      ) {
        this.formErrors["company_contacts"] = [];
      }
    },
    checkData(obj, key) {
      if (obj) {
        if (key == "country") {
          this.companyDetails.phone_code = obj.phone_code;
          this.getAllCityList({ country: obj.id });
        }
        this.companyDetails[key] = obj.id;
      }
    },

    // Method for documents
    uploadDocument(id = null) {
      this.documentList.forEach((details, index) => {
        let payload = {
          id: null,
          data: null,
        };
        payload.id = id;
        let newForm = new FormData();
        newForm.append("document_type", details.document_type);
        newForm.append("document", details.file);
        if (details.description) {
          newForm.append("description", details.description);
        }
        if (details.document_exp_date) {
          newForm.append("document_exp_date", details.document_exp_date);
        }

        payload.data = newForm;
        this.$api.company
          .uploadCompanyDocuments(payload)
          .then((response) => {
            this.$router.push({
              path: "/app/admin/company-management/transporter/list",
            });
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },
    deleteDocument(document, index) {
      if (
        confirm(`Are you sure you want to delete ${document.document_type} ?`)
      ) {
        this.$bus.$emit("showLoader", true);
        this.$api.company
          .deleteCompanyDocument(document.id)
          .then((response) => {
            this.companyDetails.company_documents.splice(index, 1);
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      }
    },

    getContainerCategoryList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
      };

      this.$api.containerCategory
        .getContainerCategoryList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allContainerCategoryList = res.data;
          this.totalItems = res.count;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    generateUrl(file) {
      return URL.createObjectURL(file);
    },
    getSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    removeFile(index) {
      this.documentList.splice(index, 1);
    },
    checkFileFormat(event) {
      const name = event.name;
      const lastDot = name.lastIndexOf(".");
      const ext = name.substring(lastDot + 1);
      let allowed = /(\jpg|\jpeg|\pdf|\gif|\png)$/i;
      if (!allowed.exec(ext)) {
        this.$nextTick(() => {
          this.uploadFiles = null;
        });
        return false;
      } else {
        return true;
      }
    },
    getFileData(fileInput) {
      if (fileInput) {
        if (this.checkFileFormat(fileInput)) {
          if (fileInput && this.uploadDocumentType) {
            this.documentList.push({
              file: fileInput,
              document_type: this.uploadDocumentType,
              description: this.uploadDescription,
              document_name: this.uploadDocumentType,
              document_exp_date: this.uploadExpiryDate,
            });
            this.uploadDocumentType = null;
            this.uploadFiles = null;
            this.uploadDescription = null;
            this.uploadExpiryDate = null;
          } else {
            this.uploadDocumentType = null;
            this.uploadFiles = null;
            this.uploadDescription = null;
            this.uploadExpiryDate = null;
            this.$bus.$emit("showToastMessage", {
              message: "Select Document type first!",
              color: "error",
            });
            return false;
          }
        } else {
          alert("This file format is not Supported");
        }
      }
    },
    submitForm() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        ...this.companyDetails,
        company_contacts: this.contactArray.filter(
          (contact) => Object.keys(contact).length > 0
        ),
      };
      this.$api.company
        .updateCompany({
          id: this.companyDetails.id,
          data: payload,
        })
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Company updated successfully!",
            color: "success",
          });
          if (this.documentList.length > 0) {
            this.uploadDocument(this.companyDetails.id);
          } else {
            this.$router.push("/app/admin/company-management/transporter/list");
          }
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't create Company!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },

    getCompanyOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.company
        .getCompanyOptions()
        .then((res) => {
          this.paymentTerms = res.data.actions.POST.payment_term.choices.map(
            (obj) => {
              return { text: obj.display_name, value: obj.value };
            }
          );

          this.serviceTypeList = res.data.actions.POST.service_type.choices.map(
            (obj) => {
              return { text: obj.display_name, value: obj.value };
            }
          );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getCompanyDocumentOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.company
        .getCompanyDocumentOptions()
        .then((res) => {
          this.CompanyDocumentType =
            res.data.actions.POST.document_type.choices.map((obj) => {
              return { text: obj.display_name, value: obj.value };
            });
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getCompanyObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.company
        .getCompanyObject(id)
        .then((res) => {
          let obj = {
            ...res.data,
            rating: parseFloat(res.data.rating),
          };
          this.companyDetails = obj;

          if (obj.company_contacts && obj.company_contacts.length) {
            this.contactArray = obj.company_contacts;
          }

          this.getAllCityList();
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
        });
    },

    getAllCountryList() {
      this.$bus.$emit("showLoader", true);
      this.$api.country
        .getCountryList({ limit: "all" })
        .then((res) => {
          this.countryList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getAllCityList(params = {}) {
      this.$bus.$emit("showLoader", true);
      this.$api.city
        .getCityList({ ...params, limit: "all" })
        .then((res) => {
          this.cityList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getAllCurrencyList() {
      this.$bus.$emit("showLoader", true);
      this.$api.currency
        .getCurrencyList({ limit: "all" })
        .then((res) => {
          this.currencyList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getDepartmentsList() {
      let params = {
        limit: "all",
      };
      this.$api.department
        .getDepartmentList(params)
        .then((res) => {
          this.allDepartmentsList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getPhoneCodeList() {
      let params = {
        limit: "all",
      };
      this.$api.country
        .getCountryList(params)
        .then((res) => {
          this.allPhoneCodeList = res.data.map((obj) => obj.phone_code);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("transporter");
      if (!permissions || !permissions.change) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.getCompanyOptions();
        this.getCompanyDocumentOptions();
        this.getAllCountryList();
        this.getPhoneCodeList();
        this.getDepartmentsList();
        this.getAllCurrencyList();
        this.getCompanyObject(this.$route.params.id);
        this.getContainerCategoryList();
      }
    }
  },
};
</script>
