import { containerCategory } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getContainerCategoryList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(containerCategory.containerCategoryBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getContainerCategorySelectList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${containerCategory.containerCategoryBase}select/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getContainerCategoryOptions() {
        return new Promise((resolve, reject) => {
            axios
                .options(containerCategory.containerCategoryBase)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addContainerCategory(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(containerCategory.containerCategoryBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getContainerCategoryObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${containerCategory.containerCategoryBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateContainerCategory(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${containerCategory.containerCategoryBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    partiallyUpdateContainerType(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${containerCategory.containerCategoryBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
});