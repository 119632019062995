var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1 mr-2",attrs:{"color":"secondary"},on:{"click":_vm.viewLegInfo}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}])},[_c('span',[_vm._v("View")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.data &&
          [
            'Created',
            'Assigned',
            'Enroute',
            'Reached Pickup Location',
            'Reached Drop Location',
          ].indexOf(_vm.params.data.status) > -1
        )?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1 mr-1",attrs:{"color":"primary"},on:{"click":_vm.editLeg}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}])},[_c('span',[_vm._v("Edit")])]),(
      _vm.params &&
      _vm.params.data &&
      _vm.params.context &&
      _vm.params.context.parentComponent &&
      _vm.params.context.parentComponent.selectedDaDetails &&
      _vm.params.context.parentComponent.selectedDaDetails.legs_count &&
      _vm.params.context.parentComponent.selectedDaDetails.legs_count > 1 &&
      ['Created', 'Assigned'].indexOf(_vm.params.data.status) > -1 &&
      _vm.params.context.parentComponent.selectedDaDetails.is_sub_hire != 'Yes'
    )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-1",attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteLeg()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,false,1430080522)},[_c('span',[_vm._v("Delete")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }