import { render, staticRenderFns } from "./TripDetails.vue?vue&type=template&id=05024827&scoped=true"
import script from "./TripDetails.vue?vue&type=script&lang=js"
export * from "./TripDetails.vue?vue&type=script&lang=js"
import style0 from "./TripDetails.vue?vue&type=style&index=0&id=05024827&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05024827",
  null
  
)

export default component.exports